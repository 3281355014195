import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { HomePageService } from '../../../../../services/home-page.service'
import { BannerModel } from '../../../../../common/models/bannerModel'
import { Router } from '@angular/router'
import { TemplateConstants } from '../../../../commonComponent/template-constants';
import { Constants } from '../../../../../common/constants/lsnetx.constants';
import { isPlatformBrowser } from '@angular/common';

/**
 * This component displays offers present in the store.
 */
@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit, OnDestroy {

  constructor(public homePageService: HomePageService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    ) { }

  offers: Array<BannerModel> = [];
  defaultPath = TemplateConstants.templateAssetsPath + '/images/offer/offer1.jpg';
  demoOffer: BannerModel = new BannerModel();
  isFirstChange: boolean;
  _homePageSubscription;
  isMobile: boolean = false  
  
  /**
   * Fetches banner for the store and populate them. If no banner is present, then populate default banner for that template.
   */
  ngOnInit() {

    if (isPlatformBrowser(this.platformId)) {
      if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Mobi/i.test(window.navigator.userAgent)) {
        this.isMobile = true
      } else if (/Mobi|Tablet|iPad|kindle/i.test(window.navigator.userAgent)) {
        this.isMobile = false
      } else {
        this.isMobile = false
      }
    }

    this._homePageSubscription = this.homePageService.bannersOffersObservable.subscribe((data) => {
      if (data && data.length) {
        this.offers = []
        let bannerOffers: Array<BannerModel> = data;
        bannerOffers.forEach((ele) => {
          if (ele.bannerType == Constants.BANNER_TYPE.OFFER) {
            this.offers.push(ele);
          }
        });
        // console.log("offers", this.offers);
        if (this.offers.length > 0) {

        } else {
          this.setDefaultOffers();
        }
      } else {
        this.setDefaultOffers();
      }
    });
  }

  /**
   * unsubscribe all subscriptions
   */
  ngOnDestroy(){
    if(this._homePageSubscription){
      this._homePageSubscription.unsubscribe();
    }
  }

  /**
   * Fetches banners from assets and populates them.
   */
  setDefaultOffers() {
    this.demoOffer.imageName = TemplateConstants.templateAssetsPath + "/images/offer/offer1.jpg";
    this.demoOffer.altText = 'defaultOffer1';
    this.demoOffer.videoURL = "";
    this.offers.push(this.demoOffer);
    this.demoOffer = new BannerModel();
    this.demoOffer.imageName = TemplateConstants.templateAssetsPath + "/images/offer/offer2.jpg";
    this.demoOffer.altText = 'defaultOffer2';
    this.demoOffer.videoURL = "";
    this.offers.push(this.demoOffer);
    this.demoOffer = new BannerModel();
    this.demoOffer.imageName = TemplateConstants.templateAssetsPath + "/images/offer/offer3.jpg";
    this.demoOffer.altText = 'defaultOffer3';
    this.demoOffer.videoURL = "";
    this.offers.push(this.demoOffer);
    this.demoOffer = new BannerModel();
    this.demoOffer.imageName = TemplateConstants.templateAssetsPath + "/images/offer/offer4.jpg";
    this.demoOffer.altText = 'defaultOffer4';
    this.demoOffer.videoURL = "";
    this.offers.push(this.demoOffer);
    this.demoOffer = new BannerModel();
    this.demoOffer.imageName = TemplateConstants.templateAssetsPath + "/images/offer/offer5.jpg";
    this.demoOffer.altText = 'defaultOffer5';
    this.demoOffer.videoURL = "";
    this.offers.push(this.demoOffer);
    this.demoOffer = new BannerModel();
    this.demoOffer.imageName = TemplateConstants.templateAssetsPath + "/images/offer/offer6.jpg";
    this.demoOffer.altText = 'defaultOffer6';
    this.demoOffer.videoURL = "";
    this.offers.push(this.demoOffer);
  }

  /**
   * When user clicks on any offer, then user is redirected to the url present in the BannerModel of that offer.
   * It url contains 'collections', then redirect to appropriate collection-page.
   * @param href 
   */
  navigate(href) {
    if (href != undefined && href != '') {
      if (href.indexOf('collections') > -1) {
        this.router.navigate(['collections' + href.split('collections')[1]]);
      } else {
        window.location.href = href;
      }
    }
  }
  
  /**
   * whenever image error event triggers, a default image is set, and make onerror event null, so that it doesn't trigger again.
   * @param event : image error event
   */
  offerImgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

}

import { Component, OnInit, Inject, PLATFORM_ID, HostListener, ViewChild, OnDestroy } from '@angular/core'
import { HttpService } from '../../../services/http.service'
import { isPlatformBrowser, DOCUMENT } from '@angular/common'
import { Router, RouterEvent, ActivatedRoute, NavigationEnd } from '@angular/router'
import { CategoryListPageService } from '../../../services/categoryListPage.service'
import { CategoryService } from '../../../services/category.service'
import { FilterModel } from '../../../common/models/filterModel'
import { ProductModel } from '../../../common/models/productModel'
import { Constants } from '../../../common/constants/lsnetx.constants'
import { WINDOW } from '../../../services/window.service';
import { FiltersComponent } from '../filters/filters.component'
import { SeoService } from '../../../services/seo.service';
import { CartCountService } from '../../../services/cart-count.service';
import { CartService } from '../../../services/cart.service';
import { CartModel } from '../../../common/models/cartModel';
import { CartProductModel } from '../../../common/models/cartProductModel';
import { HomePageService } from '../../../services/home-page.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LoginComponent } from '../../components/login/login.component';
import { CollectionModel } from '../../../common/models/collectionModel';
import { PartnersComponent } from '../partners/partners.component';
import { TemplateConstants } from '../../commonComponent/template-constants';
import { ProductFilterModel } from '../../../common/models/productFilterModel';
import { HttpClient } from '@angular/common/http';

/**
 * It shows products-list of a category or a sub-category.
 * It also shows products-list of a collection.
 * It also shows products-list of search-result for a search-keyword. 
 * It also shows filters, than can be applied to the products-list.
 * It also shows applied filters in form of filter tags, one can remove any filter.
 * It also shows seo-content for a category-page or collection-page.
 * It also has show-more button for loading further products (pagination).
 * @class CollectionsComponent : component for showing category-pages, collections and search-results.
 */
@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit, OnDestroy {

  @ViewChild(FiltersComponent, { static: false }) filtersComponent: FiltersComponent

  navIsFixed: boolean = false;
  pageTitle: string = ""
  currType: string = "INR"
  catgName: string
  products: Array<ProductModel> = []
  currencyId
  categoryId
  parentCategoryUrl: string = '';
  pageNum
  numRecords
  divisionId
  FilterModel: Array<FilterModel> = []
  categoryList = []
  CATEGORIES: Array<{ catName: string, catId: string, catUrl: string }> = []
  catMap: Array<{ catName: string, catUrl: string }> = []
  hasMore: boolean = false
  list_View: boolean = false
  filterList: Array<FilterModel> = []
  filterId = []
  filterValueId = []
  listViewStr = "grid";
  searchResultsPage: boolean = false;
  showError: boolean = false;
  parentClass: string = "";
  qParams
  params
  route
  content: string
  cdnPathForContent: string
  showSeo: boolean = true
  errorPage: boolean = false
  errorPageUrl = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.errorPage400 ? Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.errorPage400 : TemplateConstants.templateAssetsPath + '/images/errorPages/404_pageNotFound.jpg';
  searchPageNum = 1;
  offerPageNum: number = 1;
  _cartCountSubscription;
  _wishlistSubscription;
  _routeSubscription;
  _catSubscription;
  _paramsSubscription;
  _homePageSubscription;
  forTemplate: boolean = false;
  _fetchCollectionOnUrl: boolean;
  collectionProductLinkType: number = -1;
  isCollectionUrl: boolean;
  searchKey: string;
  isAuthorPage: boolean;
  brandUrl: string;
  routerNavFlag: boolean;
  cartSettings = Constants.ONLINE_STORE_SETTING_MODEL.cartSettingsDTO;
  showPageNotFound: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private categoryListPageService: CategoryListPageService,
    private categoryService: CategoryService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private categoryListService: CategoryListPageService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private httpService: HttpService,
    private http: HttpClient,
    private seoService: SeoService,
    public cartCountService: CartCountService,
    public cartService: CartService,
    private homePageService: HomePageService,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
    public dialogService: LsDialogService<LoginComponent>,
    private partnerDialogService: LsDialogService<PartnersComponent>
  ) { }

  /**
   * First, find-out currency with the help of currencyID stored in session-storage of browser.
   * Then, subscribe router-events for detecting any route-changes.
   * Then, based upon the route, figure-out what is current-page i.e;(Category-page, Collection-page, Search-page, Author-page (in case of Bookstime))
   */
  ngOnInit() {
    /**
     * get currency code of current currency
     */
    if (isPlatformBrowser(this.platformId)) {
      if (window.sessionStorage.getItem('currencyId') != null) {
        let currId = JSON.parse(window.sessionStorage.getItem('currencyId'));
        let currMap = JSON.parse(window.sessionStorage.getItem('currencyMap'));
        currMap.forEach(element => {
          if (element.id == currId) {
            this.currType = element.code;
          }
        });
      }
    }
    /**
     * for detecting, if component is loading for first-time
     */
    this.routerNavFlag = false;
    /**
     * stores current route.
     */
    this.route = this.router.url;
    /**
     * flag to know whether route-change has been detected or not, (If not, then it means component is loaded for the very first-time).
     */
    this._routeSubscription = this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        let newUrl = e.url;
        this.route = newUrl;
        this.routerNavFlag = true;
        /**
         * whenever, route-changes, call method for detecting current-page based upon current-route.
         */
        this.urlUtility(this.routerNavFlag);
      }
    });
    /**
     * If component is loading for the very first-time, router-change will not be detected, then call method for detecting current-page based upon current-route.
     */
    if (!this.routerNavFlag) {
      this.urlUtility(this.routerNavFlag);
    }
  }

  /**
   * If current-route includes: 
   * 'collections' - then it means, it can be either category-page or collection-page
   * 'search' - then it means, it is search-result page
   * 'author' - then it means, it is author-page for listing products of same author (only in case of bookstime)
   * Detects current-page based upon current-route
   * @param routerNavFlag : flag to know whether route-change has been detected or not
   */
  urlUtility(routerNavFlag) {
    this.pageNum = 1;
    if (this.route.includes('collections/')) {
      this.collectionUrlUtility(routerNavFlag, this.pageNum);
    } else if (this.route.includes('search?')) {
      this.searchUrlUtility();
    } else if (this.route.includes('author/')) {
      this.authorUrlUtility();
    } else {
      this.showPageNotFound = true;
    }
  }

  /**
   * checks if string after '/collections/' is of categoryUrl or CollectionUrl.
   * This method is for detecting if current-route is of a collection or of a category.
   * @param routerNavFlag : flag to know whether route-change has been detected or not
   * @param pageNum : current page-number of products-list
   */
  collectionUrlUtility(routerNavFlag, pageNum) {
    /**
     * get string after '/collections/' from current-route
     */
    this.parentCategoryUrl = this.route.split("collections/")[1].split("?")[0];
    var collectionArray: Array<CollectionModel>;
    /**
     * Get collections-list from collectionObservable 
     */
    this._homePageSubscription = this.homePageService.collectionsObservable.subscribe((data) => {
      collectionArray = data;
      if (collectionArray && collectionArray.length > 0) {
        /**
         * check, if url after 'collections/' if from collectionUrl or not
         */
        this.isCollectionUrl = this.checkIfCollectionUrl(collectionArray);
        /**
         * If yes, then get products-list from collectionUrl
         */
        if (this.isCollectionUrl) {
          this.fetchCollectionOnUrl(this.parentCategoryUrl, pageNum);
          /**
           * else, get products-list from categoryUrl
           */
        } else {
          this.collectionUtility(routerNavFlag, pageNum);
        }
      }
    });
    /**
     * If no data present in observable, then get data from request for home-page collections.
     */
    if (!(collectionArray && collectionArray.length > 0)) {
      var isDetailPage = false, fetchProducts = true, categoryId;
      this.homePageService.getHomePageCollectionIn(isDetailPage, fetchProducts, categoryId, (data) => {
        if (!data.error && data.data) {
          collectionArray = data.data;
          if (collectionArray && collectionArray.length > 0) {
            this.isCollectionUrl = this.checkIfCollectionUrl(collectionArray);
            if (this.isCollectionUrl) {
              this.fetchCollectionOnUrl(this.parentCategoryUrl, pageNum);
            } else {
              this.collectionUtility(routerNavFlag, pageNum);
            }
          }
        } else {
          this.collectionUtility(routerNavFlag, pageNum);
        }
      });
    }
  }

  /**
   * sets if, current page is 
   * If search-page and if current-route has 'k', queryParam for storing search-keyword, then get products-list for that search-keyword.
   * If current-route doesn't have 'k', then show error-page.
   * Checks if search-page has search-keyword or not. If it has, then fetch products-list otherwise show error-page.
   */
  searchUrlUtility() {
    let queryParamsMap = this.activatedRoute.snapshot.queryParamMap;
    if (queryParamsMap != undefined && queryParamsMap.get("k") != undefined && queryParamsMap.get("k") != "") {
      this.getSearchData(this.searchPageNum);
      this.searchResultsPage = true;
      this.searchPageNum = 1;
    } else {
      this.showError = true;
      this.parentClass = "no-results-found";
    }
  }

  /**
   * set authorPage true,
   * get brandUrl from current-route
   * Fetches products for the brandUrl. 
   */
  authorUrlUtility() {
    this.isAuthorPage = true;
    this.brandUrl = this.route.split("author/")[1].split("?")[0];
    this.getBrandData(1);
  }

  /**
   * Fetches products for a brand (author in case of bookstime)
   * If products not found, then show error page.
   * @param pageNum : page number for pagination
   */
  getBrandData(pageNum: number) {
    if (this.currType != undefined) {
      this.currencyId = 1;
    }
    let brandUrl
    if (this.router.url && this.router.url.split('author/')[1]) {
      brandUrl = this.router.url.split("author/")[1].split("?")[0];
    }
    if (this.brandUrl == undefined) {
      this.brandUrl = brandUrl;
    } else {
      if (this.brandUrl && this.brandUrl.split('collections/')[1]) {
        this.brandUrl = this.brandUrl.split("collections/")[1].split("?")[0];
      }
    }
    this.pageNum = pageNum;
    this.divisionId = 0;
    this.numRecords = Constants.CATEGORY_PAGE_NUM_RECORDS;
    this.categoryListPageService.getProductsByBrand(this.currencyId, this.brandUrl, this.pageNum, this.numRecords, this.divisionId, (resp) => {
      if (!resp.error && resp.data && resp.data.list && resp.data.list.length > 0) {
        this.errorPage = false;
        this.hasMore = resp.data.hasMore;
        if (this.pageNum == 1) {
          let productList = resp.data.list;
          this.products = productList;
        } else {
          let productList = resp.data.list;
          productList.forEach((ele) => {
            this.products.push(ele);
          })
        }
      } else {
        this.errorPage = true;
      }
    });
  }

  /**
   * on component destroy, unsubscribe all subscriptions 
   */
  ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    if (this._paramsSubscription) {
      this._paramsSubscription.unsubscribe();
    }
    if (this._homePageSubscription) {
      this._homePageSubscription.unsubscribe();
    }
  }

  /**
   * If route-change is not detected, then fetch filters and update selected filters according to the query parameters (selected filters are stored in query parameters, to maintain it on page-refresh)
   * If route-change is detected (but not due to change in query parameters), then also fetch filters and
   * Whenever there is change is query-parameters, update selected filters according to them.
   * Fetches filters of the category and then fetch products of the category
   * @param routerNavFlag : flag to know whether route-change has been detected or not
   * @param pageNum : page-number for pagination
   */
  collectionUtility(routerNavFlag, pageNum) {
    this.searchResultsPage = false;
    if (!routerNavFlag) {
      this.route = this.router.url;
      let queryParams = this.router.routerState.snapshot.root.queryParams;
      this.setQueryParams(queryParams);
      this.getFilters(this.parentCategoryUrl, pageNum);
    }
    if (!this.route.includes('?') && routerNavFlag) {
      this.getFilters(this.parentCategoryUrl, pageNum);
    }
    this._paramsSubscription = this.activatedRoute.queryParams.subscribe(data => {
      this.setQueryParams(data);
    });
  }

  /**
   * Checks if url after '/collections/' is of collectionUrl or not.
   * @param collectionArray : list of collections.
   * @returns true, if url is of collectionUrl, else false.
   */
  checkIfCollectionUrl(collectionArray: Array<CollectionModel>): boolean {
    var found: boolean = false;
    if (collectionArray && collectionArray.length > 0) {
      for (let index = 0; index < collectionArray.length; index++) {
        var element = collectionArray[index];
        if (this.parentCategoryUrl.includes(element.collectionURL)) {
          found = true;
          break;
        }
      }
    }
    return found;
  }

  /**
   * If queryparams are present, then set queryParams values to filterId array and filterValueId array.
   * @param queryParams : query-parameters of current route where filterId and filter's parent id of selected filters are stored.
   */
  setQueryParams(queryParams) {
    if (queryParams && queryParams.setTypeData != undefined && queryParams.dataFor != undefined) {
      let parentIdx = queryParams.setTypeData;
      let currentIdx = queryParams.dataFor;
      let parentIdxArr = [];
      let currentIdArr = [];
      parentIdx.split('#').forEach(ele => {
        parentIdxArr.push(parseInt(ele));
      });
      currentIdx.split('#').forEach(ele => {
        currentIdArr.push(parseInt(ele));
      });
      this.filterId = parentIdxArr;
      this.filterValueId = currentIdArr;
    } else {
      this.filterId = [];
      this.filterValueId = [];
    }
  }

  /**
   * Fetches product after increasing page number.
   * shows more products of a category or a collection or a search-result page.
   */
  showMore() {
    if (this.currType != undefined) {
      this.currencyId = 1;
    }
    if (this.searchResultsPage && !this.isAuthorPage) {
      this.getSearchData(this.searchPageNum + 1);
    } else if (this.isAuthorPage && !this.searchResultsPage) {
      this.getBrandData(++this.pageNum);
    } else {
      this.collectionUrlUtility(this.routerNavFlag, ++this.pageNum);
    }
  }

  /**
   * Fetch products for a collection using collectionUrl
   * @param parentCategoryUrl : collectionUrl
   * @param pageNum : pageNumber for pagination
   */
  fetchCollectionOnUrl(parentCategoryUrl, pageNum) {
    this._fetchCollectionOnUrl = true;
    let url = parentCategoryUrl;
    if (this.currType != undefined) {
      this.currencyId = 1;
    }
    this.offerPageNum = pageNum;
    this.categoryListPageService.fetchCollectionDetailsOnUrl(this.offerPageNum, Constants.CATEGORY_PAGE_NUM_RECORDS, url, this.currencyId, (data) => {
      if (!data.error) {
        if (data.data != null && data.data != undefined) {
          this.errorPage = false;
          let collectionData: CollectionModel;
          collectionData = data.data;
          this.pageTitle = data.data.collectionTitleName;
          this.hasMore = data.data.hasMore;
          if (collectionData.productModels != null && collectionData.productModels != undefined && collectionData.productModels.length > 0) {
            if (this.offerPageNum == 1) {
              this.products = [];
            }
            let products = collectionData.productModels;
            products.forEach(ele => {
              this.products.push(ele);
            });
          }
        }
      } else {
        /**
         * if products not fetched, then show error page
         */
        this.errorPage = true;
      }
    })
  }

  /**
   * Fetches products of a category-url with updated filterModel and page number.
   * @param filterModel :  FilterModel for selected filters
   * @param pageNum : pageNumber for pagination
   */
  getDataOnCategory(filterModel, pageNum) {
    if (this.currType != undefined) {
      this.currencyId = 1
    }
    /**
     * for nav end
     */
    let routerUrl
    if (this.router.url && this.router.url.split('collections/')[1]) {
      routerUrl = this.router.url.split("collections/")[1].split("?")[0];
    }
    if (this.parentCategoryUrl == undefined) {
      this.parentCategoryUrl = routerUrl;
    } else {
      if (this.parentCategoryUrl && this.parentCategoryUrl.split('collections/')[1]) {
        this.parentCategoryUrl = this.parentCategoryUrl.split("collections/")[1].split("?")[0];
      }
    }

    this.pageNum = pageNum;
    this.divisionId = 0;
    this.numRecords = Constants.CATEGORY_PAGE_NUM_RECORDS;
    let productFilterModel = new ProductFilterModel();
    productFilterModel.categoryUrl = this.parentCategoryUrl;
    productFilterModel.pageNum = this.pageNum;
    productFilterModel.numRecords = this.numRecords;
    productFilterModel.currencyId = this.currencyId;
    productFilterModel.divisionId = this.divisionId;
    productFilterModel.filterModels = filterModel;
    productFilterModel.productOnly = true;
    this.categoryListPageService.getDataOnCategory(productFilterModel, Constants.SEARCH_PRODUCTS_REQ_MODE.BOTH, (resp) => {
      if (resp != null) {
        if (!resp.error) {
          this.errorPage = false;
          if (resp.data && resp.data.data) {
            if (resp.data.data.metaTitle && resp.data.data.metaKeyword && resp.data.data.metaDesc) {
              this.seoService.updateMetaTags(resp.data.data.metaTitle, resp.data.data.metaKeyword, resp.data.data.metaDesc)
            }
            this.cdnPathForContent = resp.data.data.seoUrl
            if (this.cdnPathForContent != undefined && this.cdnPathForContent != "") {
              this.http.get(this.cdnPathForContent).subscribe((data: any) => {
                if (data != undefined && data != "")
                  this.content = data._body;
              })
            }
            this.pageTitle = resp.data.data.categoryDisplayName
            this.hasMore = resp.data.hasMore
            this.catMap = []
            Object.keys(resp.data.map).forEach(ele => {
              this.catMap.push({
                catName: ele,
                catUrl: resp.data.map[ele]
              })
            })
            if (resp.data.list && resp.data.list.length > 0) {
              this.errorPage = false;
              let productList = resp.data.list
              if (this.pageNum > 1) {
                productList.forEach((ele) => {
                  this.products.push(ele);
                })
              } else {
                this.products = resp.data.list;
              }
            } else {
              this.products = []
              this.errorPage = true
            }
          } else if (resp.data == null) {
            this.products = [];
            this.errorPage = true;
          }
        } else {
          this.products = [];
          this.errorPage = true;
        }
      }
    })
  }

  /**
   * Navigates to collection-url or category-url by appending url in collection-route.
   * @param url : collection-url or category-url
   */
  navigateToCategoryUrl(url) {
    this.router.navigate(['collections/' + url]);
  }

  /**
   * Show products-list in list-manner.
   */
  listView() {
    this.list_View = true
    this.listViewStr = "list"
  }

  /**
   * Show products-list in grid-manner.
   */
  gridView() {
    this.list_View = false
    this.listViewStr = "grid"
  }

  /**
   * Updates product-list whenever a filter is selected.
   * @param event : current filterModel
   */
  updateCollection(event) {
    this.FilterModel = [];
    this.FilterModel = event;
    this.getFilterDisplayNameList();
    this.pageNum = 1;
    /**
     * fetch products-list based upon filterModel.
     */
    this.getDataOnCategory(this.FilterModel, this.pageNum);
  }

  /**
   * Fetches filters corresponding to the category
   * @param categoryUrl : categoryUrl for which filters need to be fetched.
   * @param pageNum : page-number for that category, if page number is 1, then only fetch the filters otherwise don't.
   */
  getFilters(categoryUrl: string, pageNum) {
    /**
     * If, pageNum == 1, then only fetch filters, then set selected filters and then fetch products with updated FilterModel
     */
    if (pageNum == 1) {
      this.filterList = [];
      this.FilterModel = [];
      this.categoryListService.getFilters(categoryUrl, resp => {
        if (resp.error) {
          this.errorPage = true;
        } else {
          this.errorPage = false;
          if (resp.data != undefined) {
            resp.data.forEach(ele => {
              this.filterList.push(ele)
            })
          }
          this.setSelectedFilters();
        }
      })
    } else {
      /**
       * else fetch product-list.
       */
      this.getDataOnCategory(this.FilterModel, pageNum);
    }
  }

  /**
   * Sets selected filters and then fetch products with the updated FilterModel
   */
  setSelectedFilters() {
    if (this.filterId.length != 0 && this.filterValueId.length != 0 && this.FilterModel != undefined && this.filterList != undefined) {
      for (let i = 0; i < this.filterId.length; i++) {
        let model: FilterModel = new FilterModel(); let flag = false;
        if (this.FilterModel != undefined && this.FilterModel.length > 0) {
          for (let filter = 0; filter < this.FilterModel.length; filter++) {
            if (this.FilterModel[filter].filterId == this.filterId[i]) {
              for (let j = 0; j < this.filterList.length; j++) {
                if (this.filterList[j].filterId == this.filterId[i]) {
                  for (let k = 0; k < this.filterList[j].filterModelList.length; k++) {
                    if (this.filterList[j].filterModelList[k].filterValueId == this.filterValueId[i]) {
                      this.FilterModel[filter].filterModelList.push(this.filterList[j].filterModelList[k]);
                      flag = true;
                      break;
                    }
                  }
                  break;
                }
              }
            }
          }
        }
        if (!flag) {
          for (let i1 = 0; i1 < this.filterList.length; i1++) {
            if (this.filterList[i1].filterId == this.filterId[i]) {
              for (let j1 = 0; j1 < this.filterList[i1].filterModelList.length; j1++) {
                if (this.filterList[i1].filterModelList[j1].filterValueId == this.filterValueId[i]) {
                  model.colorAvailable = this.filterList[i1].colorAvailable
                  model.filterId = this.filterList[i1].filterId
                  model.filterName = this.filterList[i1].filterName
                  model.filterType = this.filterList[i1].filterType
                  model.filterTypeId = this.filterList[i1].filterTypeId
                  model.seqNo = this.filterList[i1].seqNo;
                  model.filterModelList = new Array();
                  model.filterModelList.push(this.filterList[i1].filterModelList[j1]);
                  this.FilterModel.push(model);
                  break
                }
              }
              break
            }
          }
        }
      }
    }
    this.getFilterDisplayNameList();
    this.pageNum = 1;
    this.getDataOnCategory(this.FilterModel, this.pageNum);
  }

  /**
   * Show filterPanel
   * @param filtersPanel : elementRef
   */
  showFilters(filtersPanel) {
    if (filtersPanel.className.includes('showFilters')) {
      filtersPanel.className = "filterParent hideFilters";
      this.showSeo = true;
    } else {
      filtersPanel.className = "filterParent showFilters";
      this.showSeo = false;
    }
  }

  /**
   * Hides filterPanel
   * @param filtersPanel : elementRef
   */
  hideFiltersPanel(filtersPanel) {
    if (filtersPanel.className.includes('showFilters')) {
      filtersPanel.className = "filterParent hideFilters";
      this.showSeo = true;
    } else {
      filtersPanel.className = "filterParent showFilters";
      this.showSeo = false;
    }
  }

  /**
   * window scroll event handler
   */
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if (window.pageYOffset > 2740) {
      this.navIsFixed = false;
    } else if (number > 160) {
      this.navIsFixed = false;
    } else if (this.navIsFixed && number < 159) {
      this.navIsFixed = false;
    }
  }

  /**
   * Fetches product-list for a search-keyword.
   * @param pageNum : page-number for pagination
   */
  getSearchData(pageNum) {
    this.searchPageNum = pageNum
    /**
     * get search-keyword from current-route's query param
     */
    let keyword = this.activatedRoute.snapshot.queryParamMap.get("k");
    this.searchKey = keyword;
    /**
     * get currencyID
     */
    if (keyword != undefined && keyword != "") {
      if (isPlatformBrowser(this.platformId)) {
        if (window.sessionStorage.getItem('currencyId') != null) {
          let currId = JSON.parse(window.sessionStorage.getItem('currencyId'));
          let currMap = JSON.parse(window.sessionStorage.getItem('currencyMap'));
          currMap.forEach(element => {
            if (element.id == currId) {
              this.currType = element.code;
            }
          });
        }
      }
      if (this.currType != undefined) {
        this.currencyId = 1
      }
      /**
       * get product-list for the search-keyword, populate product-list.
       * If error occurs, show error-page.
       */
      this.categoryListPageService.searchResults(this.currencyId, keyword, pageNum, Constants.SEARCH_PRODUCTS_REQ_MODE.BOTH, resp => {
        if (resp != null) {
          if (!resp.error) {
            if (resp.data && resp.data.list) {
              this.pageTitle = "Search Products for " + keyword;
              this.hasMore = resp.data.hasMore
              if (this.searchPageNum > 1) {
                let prodList = resp.data.list;
                prodList.forEach(ele => {
                  this.products.push(ele);
                })
              } else {
                this.products = resp.data.list;
              }
              this.searchResultsPage = true;
              this.parentClass = "searchResultsPage";
              this.showError = false;
            } else {
              this.showError = true;
              this.parentClass = "no-results-found";
            }
          } else {
            this.showError = true;
            this.parentClass = "no-results-found";
          }
        }
      })
    }
  }

  /**
   * Removes all filters that have been applied previously and update product-list
   */
  clearAllFilters() {
    /**
     * empty all arrays that hold selected filterModels, filerId, filterValueId and filterList.
     */
    this.FilterModel = [];
    this.filterList = [];
    this.filterId = [];
    this.filterValueId = [];
    this.pageNum = 1;
    /**
     * fetch product-list with empty filter-model
     */
    this.getDataOnCategory(this.FilterModel, this.pageNum)
    /**
     * remove all queryParams regarding filters that have been applied previously
     */
    this.router.navigate([this.route.split("?")[0]])
    /**
     * remove check sign from all checkboxes of filters
     */
    this.filtersComponent.checkRemoveAll()
  }

  /**
   * Removes filter and update product-list 
   * @param event : filterModel which need to be removed
   */
  remove(event) {
    if (this.FilterModel[0] != undefined && this.FilterModel[0].filterModelList.length == 0) {
      this.FilterModel = []
    }
    this.pageNum = 1;
    /**
     * fetch product-list after removing filter from FilterModel
     */
    this.getDataOnCategory(this.FilterModel, this.pageNum)
    /**
     * remove check from that filter's checkbox
     */
    this.filtersComponent.checkRemove(event.filterId, event.filterValueId)
    let len = this.filterId.length
    /**
     * remove filterId and filter's parent ID from arrays holding values
     */
    for (let i = 0; i < len; i++) {
      if (this.filterId[i] == event.filterId && this.filterValueId[i] == event.filterValueId) {
        this.filterId.splice(i, 1)
        this.filterValueId.splice(i, 1)
      }
    }
    /**
     * update query-params
     */
    this.params = {
      setTypeData: this.filterId.join('#'),
      dataFor: this.filterValueId.join('#')
    }
    /**
     * navigate to original route with updated query-params
     */
    if (this.filterId.length > 0) {
      this.qParams = Object.assign({}, this.params)
      this.router.navigate([this.route.split("?")[0]], { queryParams: this.qParams })
    } else {
      this.router.navigate([this.route.split("?")[0]])
    }
  }

  /**
   * Adds product to shopping-cart.
   * For fetching local-shopping-cart, if it is the multi-stores case, it fetches local-shopping-cart for selected store.
   * If not Login, 
   * 1. check if localCart is empty, 
   *    a)if yes, then create and update new cart and save new cart in localStorage and update observable.
   *    b)if no, then update localCart and save updated cart in localStorage and update observable.
   * If Login (
   *    1. get prevUserCart and check if localCart is empty, then create and update new cart with prevUserCart
   *      and save it in localStorage and update cartObsevable.
   *      if local is not empty, then update prevUserCart with localCart and save it in localStorage and update
   *      cartObservable.)
   * @param event : titleId of the product which needs to be added in the shopping-cart.
   */
  addToCartProduct(event) {
    if (isPlatformBrowser(this.platformId)) {
      let localCart: CartModel = this.cartCountService.getLocalCartForStore();
      let newProd: CartProductModel = new CartProductModel();
      newProd.titleId = Number.parseInt(event);
      newProd.numCopies = 1;
      /**
       * sucessful login
       */
      if (window.localStorage.getItem('currentUser') !== null) {
        let newCart: CartModel = new CartModel();
        newCart.cartProductModels = [];
        newCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
        if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
          localCart.cartProductModels.push(newProd);
          newCart.cartProductModels = localCart.cartProductModels;
        } else {
          newCart.cartProductModels.push(newProd);
        }
        if (!this.checkProductAlreadyPresent(event, false)) {
          /**
           * compute updated shopping-cart
           */
          this.cartService.computeCart(newCart, (resp) => {
            if (!resp.error && resp.data) {
              /**
               * update updated shopping-cart in local-storage
               */
              this.setLocalCart(resp.data[0], false);
              /**
               * update shopping-cart observable with updated shopping-cart
               */
              this.cartCountService.updateCart(resp.data[0]);
              this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Product Added to Cart!');
            } else {
              this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Error in adding product to cart!');
            }
          });
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
        }
      } else {
        /**
         * not logged-in
         */
        let newCart: CartModel = new CartModel();
        newCart.cartProductModels = [];
        newCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
        if (localCart) {
          localCart.cartProductModels.push(newProd);
          newCart.cartProductModels = localCart.cartProductModels;
        } else {
          newCart.cartProductModels.push(newProd);
        }
        if (!this.checkProductAlreadyPresent(event, false)) {
          /**
           * update updated local shopping-cart
           */
          this.setLocalCart(newCart, false);
          /**
           * update shopping-cart observable with updated shopping-cart
           */
          this.cartCountService.updateCart(newCart);
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Product Added to Cart!');
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
        }
      }
    }
  }

  /**
   * Adds product to wishlist if user is logged-in, else first open login pop-up and after sucessful login, add product to wishlist.
   * @param event : product that needs to be added to the wishlist.
   */
  addToWishlist(event: CartProductModel) {
    /**
     * check  if login or not
     */
    if (window.localStorage.getItem('currentUser') !== null) {
      /**
       * if logged-in, then add product to wishlist
       */
      this.addToCartWishlistUtility(event, false);
    } else {
      /**
       * not logged-in, then open login pop-up
       */
      this.dialogService.open(LoginComponent, { panelClass: 'loginPopUp' }, null).subscribe(response => {
        /**
         * If login was sucessful, add product to wishlist
         */
        if (window.localStorage.getItem('currentUser') !== null) {
          this.addToCartWishlistUtility(event, false);
        }
      });
    }
  }

  /**
   * Adds a product to the wishlist.
   * First, get the current wish-list from local-storage,
   * then, check that if product is already present in the wish-list or not.
   * if not already present, push product in the beginning of the exisiting product-list, and call method for compute-cart and update local-wishlist.
   * else show error-msg.
   * @param event : product-model which needs to be added to the wish-list.
   * @param isLoginAction : deprecated (only used when cart is shown on a page on a route, not as a dialog)
   */
  addToCartWishlistUtility(event: CartProductModel, isLoginAction: boolean) {
    let msg = 'Added to your Wishlist!';
    let errMsg = 'Error in adding product to wishlist!';
    let localWishlistCart: CartModel;
    if (localStorage.getItem('wishlist')) {
      localWishlistCart = JSON.parse(localStorage.getItem('wishlist'));
    }
    let newWishlistCart: CartModel = new CartModel();
    newWishlistCart.cartProductModels = [];
    newWishlistCart.cartProductModels.push(event);
    newWishlistCart.cartWishListType = Constants.CART_WISHLIST_TYPE.WISHLIST;
    if (localWishlistCart && localWishlistCart.cartProductModels && localWishlistCart.cartProductModels.length > 0) {
      localWishlistCart.cartProductModels.forEach((ele: CartProductModel) => {
        newWishlistCart.cartProductModels.push(ele);
      });
    }
    if (!this.checkProductAlreadyPresent(event.titleId, true)) {
      this.cartService.computeCart(newWishlistCart, (resp) => {
        if (!resp.error && resp.data && resp.data[0]) {
          this.setLocalCart(resp.data[0], true);
          this.okayMessageDialogService.open(OkayMessageComponent, {}, msg);
        }
      });
    } else {
      this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
    }
  }

  /**
   * update shopping-cart or wish-list in local-storage according to the CartModel.
   * @param cart : CartModel which needs to be updated.
   * @param isWishlist : flag, if cart is shopping-cart or wishlist.
   */
  setLocalCart(cart: CartModel, isWishlist: boolean) {
    this.cartCountService.setLocalCart(cart, isWishlist);
  }

  /**
   * checks a product is already present in shopping-cart or in wish-list.
   * In multi-store case, it fetches local-shopping-cart for the selected store.
   * @param id : titleID of product.
   * @param isWishlist : flag where to check for product, in shopping-cart or in wish-list.
   * @returns boolean : true, if product is already present in shopping-cart or in wishlist, else returns false.  
   */
  checkProductAlreadyPresent(id: number, isWishlist: boolean): boolean {
    let localCart;
    if (isWishlist) {
      localCart = JSON.parse(localStorage.getItem('wishlist'));
    } else {
      localCart = this.cartCountService.getLocalCartForStore();
    }
    let present: boolean;
    if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
      for (let index = 0; index < localCart.cartProductModels.length; index++) {
        var element = localCart.cartProductModels[index];
        if (element.titleId == id) {
          present = true;
          break;
        }
      }
    }
    if (present) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * opens getForMe dialog, when user clicks on getForMe button
   */
  getForMe() {
    this.partnerDialogService.open(PartnersComponent, {}, '').subscribe({});
  }

  /**
   * updates filterModel according to tag-container required model.
   */
  getFilterDisplayNameList() {
    if (this.FilterModel && this.FilterModel.length > 0) {
      for (let index = 0; index < this.FilterModel.length; index++) {
        const ele = this.FilterModel[index];
        if (ele && ele.filterModelList && ele.filterModelList.length > 0) {
          for (let j = 0; j < ele.filterModelList.length; j++) {
            const element = ele.filterModelList[j];
            element.displayValue = element.filterDisplayValue;
          }
        }
      }
    }
  }

  /**
   * redirects to home
   * For multi-store, redirect user to 'store/<current store domain>',
   * Else, redirect user to ' ' (empty) route
   */
  navigateToHome() {
    if (Constants.IS_MULTI_STORE && this.cartCountService.getSelectedStoreDomain()) {
      let storeHomeUrl = '/store/' + this.cartCountService.getSelectedStoreDomain();
      this.router.navigate([storeHomeUrl]);
    } else {
      this.router.navigate(['']);
    }
  }

}

<footer *ngIf="!checkOutVisibility">
  <div class="footer-mb tableDiv">
    <div class="upper">
      <div class="newsletter-container floatedLeft">
        <!-- <p id="hdr">Join teeshirt Express</p> -->
        <div *ngIf="showNewsletter" class="newsletter" fxLayout="row">
          <input type="email" id="subsEmail" placeholder="Subscribe to our newsletter" class="tfSubscriptionEmail"
            #inputVal />
          <p class="send-news okLbl" fxLayoutAlign="center center" (click)="subscribeNewsletter(error, inputVal)">
            <span>SIGN IN</span>
          </p>
          <span #error class="hide errorLbl"></span>
        </div>
      </div>
      <div class="social-share-container floatedLeft">
        <div *ngIf="(socialSettings?.fbPageURL && socialSettings?.fbPageURL != '') || (socialSettings?.googlePageURL && socialSettings?.googlePageURL != '')
        || (socialSettings?.linkedInPageURL && socialSettings?.linkedInPageURL != '') || (socialSettings?.twitterPageURL && socialSettings?.twitterPageURL != '')"
          class="social-share">
          <p id="lbl">Stay with us</p>
          <div class="wrapper">
            <a *ngIf="socialSettings?.fbPageURL && socialSettings?.fbPageURL!= ''" href={{socialSettings.fbPageURL}}
              class="facebook" title="Facebook"><span></span></a>
            <a *ngIf="socialSettings?.googlePageURL && socialSettings?.googlePageURL!= ''" href={{socialSettings.googlePageURL}}
              class="gPlus" title="gPlus"><span></span></a>
            <a *ngIf="socialSettings?.linkedInPageURL && socialSettings?.linkedInPageURL!= ''" href={{socialSettings.linkedInPageURL}}
              class="linkedin" title="LinkedIn"><span></span></a>
            <a *ngIf="socialSettings?.twitterPageURL && socialSettings?.twitterPageURL!= ''" href={{socialSettings.twitterPageURL}}
              class="twitter" title="Twitter"><span></span></a>
          </div>
        </div>
      </div>
      <div class="contactDtlPnl floatedLeft">
        <p id="lbl">Call Us</p>
        <div>
          <p class="lblStores">{{vendorDetails.ccContactNo}}</p>
        </div>
      </div>
    </div>
    <div class="lower">
      <div class="account-info floatedLeft">
        <p class="heading" id="lbl">MY ACCOUNT</p>
        <div>
          <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/profile' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Profile</a>
          <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/orders' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Orders</a>
          <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/transactions' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Transaction
            Logs</a>
          <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/change-password' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Change
            Password</a>
          <a class="lnkStores" *ngIf="((constants.IS_MULTI_STORE && isStoreSelected) || !constants.IS_MULTI_STORE) && allowWishlist"
            [routerLink]="loggedInUser ? 'my-dashboard/wishlist' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Wishlist</a>
        </div>
      </div>
      <div class="storePnl floatedLeft" *ngIf="(constants.IS_MULTI_STORE && isStoreSelected) || !constants.IS_MULTI_STORE">
        <p id="lbl">Shop with us!</p>
        <ul>
          <li *ngFor="let category of categoryList">
            <a [routerLink]="category?.categoryURL | urlFormatter : constants?.ULR_FORMATTER?.COLLECTION" class="lnkStores">{{category?.categoryDisplayName}}</a>
          </li>
        </ul>
      </div>
      <div *ngIf="quickLinks != undefined" class="quicklinks floatedLeft">
        <p class="heading" id="lbl">QUICK LINKS</p>
        <ul>
          <li *ngFor="let quickLink of quickLinks">
            <a *ngIf="quickLink.pageURL.includes('http')" [href]="httpUrl" class="lnkStores" target="_blank">{{quickLink.linkName}}</a>
            <a *ngIf="!quickLink.pageURL.includes('http')" [href]="quickLink.pageURL" class="lnkStores">{{quickLink.linkName}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="copyright" fxLayout="row">
    <div class="defaultWidth">
      <p class="copyright-txt" fxFlex="50">{{copyRTxt}}</p>
      <div class="power-by" fxLayout="row" fxLayoutAlign="end end" fxFlex="50">
        <p class="power-txt">Powered By</p>
        <a href="http://www.lsnetx.com" target="_blank" class="copy-right-img"><img src="assets/logo.png" title="LSNetX"
            alt="LSNetX"></a>
      </div>
    </div>
  </div>
</footer>
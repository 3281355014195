<!-- <div  id="carousel-container" [style.height]="height" [style.width]="width" (mouseenter)="pauseCarousel()"
  (mouseleave)="resumeCarousel()">
  <i class="material-icons md-48 indigo-text text-lighten-1 carousel-icon" id="left-icon" (click)="slideLeft()">keyboard_arrow_left</i>
  <i class="material-icons md-48 indigo-text text-lighten-1 carousel-icon" id="right-icon" (click)="slideRight()">keyboard_arrow_right</i>
  <a [href]="currentClickAction">
  <img [src]="currentUrl" class="carousel-image" [title]="currentTitle">
</a>
  <div *ngIf="showIndicators" id="indicators-container">
    <ng-container>
      <span class="indicator" *ngFor="let indicatorIndex of indicators" #indicator (click)="openImage(indicatorIndex)"></span>
    </ng-container>
  </div>
</div> -->

<div (touchstart)="touchHandler($event, 'start')" (touchend)="touchHandler($event, 'end')" class="z-depth-2" style="display: block; position: relative"
  id="carousel-container" [style.height]="height" [style.width]="width">
  <!-- <i class="material-icons md-48 indigo-text text-lighten-1 carousel-icon" id="left-icon" (click)="slideLeft()">keyboard_arrow_left</i>
  <i class="material-icons md-48 indigo-text text-lighten-1 carousel-icon" id="right-icon" (click)="slideRight()">keyboard_arrow_right</i> -->
  <div *ngFor="let element of carouselElements; let idx = index" class="child" [@carouselElement]="states[idx]" [style.left]="lefts[index]"
    [title]="element.title" (click)="handleClick(idx)">
    <img [src]="(element?.imageUrl)? element.imageUrl: defaultPath" (error)="imgErrorHandler($event)">
    <!-- <div [style.backgroundImage]="'url('+element.imageUrl+')'" style="background-repeat: no-repeat; background-size: contain; background-position: center; margin: 0 auto; display: inline-block;cursor:pointer;"></div> -->
  </div>
  <div *ngIf="showIndicators" id="indicators-container">
    <ng-container>
      <span class="indicator unselected" *ngFor="let indicatorIndex of indicators" #indicator (click)="openImage(indicatorIndex)"></span>
    </ng-container>
  </div>
</div>
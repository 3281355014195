<div *ngFor="let collection of collectionArray; let i=index;" [ngClass]="{'hide': isDetailPage && collection?.detailPageVisibility == 0 || (collection?.collectionType == constants?.DEFAULT_COLLECTION_TYPE?.relatedProducts && collection?.productModels && collection?.productModels.length == 1 && collection?.productModels[0].titleId == newProd?.titleId)}">
  <div class="catHeadingWrapper" [ngClass]="{'boxShadow': isDetailPage}">
    <p class="catHeading" [ngClass]="{'extended-width': isDetailPage}">{{collection?.collectionTitleName}}</p>
    <div class="gridArrow" [ngClass]="{'disable': leftValue[i] <= maxLeftValue[i]  || collection?.productModels?.length <= showCount}"
      (click)="moveLeft(container, i)">
      <span>Next</span>
    </div>
    <div class="gridArrow right-margin" [ngClass]="{'disable': leftValue[i] >= 0  || collection?.productModels?.length <= showCount}"
      (click)="moveRight(container, i)">
      <span>Previous&nbsp;/</span>
    </div>
  </div>
  <div class="mainDiv">
    <div class="productsWrapper">
      <a [routerLink]="collection?.collectionURL | urlFormatter : constants?.ULR_FORMATTER?.COLLECTION" class="gridViewT" *ngIf="collection?.collectionType != constants?.DEFAULT_COLLECTION_TYPE?.relatedProducts">View
        All</a>
      <div [style.left.px]="leftValue[i]" class="productList">
        <!-- hide same product(current product in detailPage) from related products collections -->
        <div class="products" *ngFor="let productData of collection?.productModels;" [ngClass]="{'hide': collection?.collectionType == constants?.DEFAULT_COLLECTION_TYPE?.relatedProducts && productData?.titleId == newProd?.titleId, 'disabled': productData?.outOfStock == true}"
          #container>
          <app-product-tile [tile-data]="productData" style="width: 100%" (addToCartWishlist)="addToWishlist($event)"
            (addToCart)="addToCartProduct($event)"></app-product-tile>
        </div>
      </div>
    </div>
  </div>
</div>
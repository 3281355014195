import { Component, OnInit, Input, Output, EventEmitter, OnChanges, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router'
import { ProductModel } from '../../../common/models/productModel'
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LoginComponent } from '../../components/login/login.component';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { UserCartDetailDto } from '../../../common/models/userCartDetailDto';
import { UserModel } from '../../../common/models/user-model';
import { carouselElement } from '../product-tile-carousel/carousel/carousel.component';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { CartProductModel } from '../../../common/models/cartProductModel';
import { CartModel } from '../../../common/models/cartModel';
import { CartService } from '../../../services/cart.service';
import { CartCountService } from '../../../services/cart-count.service';
import { TemplateConstants } from '../../commonComponent/template-constants';

/**
 * This component is displayed as product-tile. 
 * It contains add-to-wishlist, add-to-cart buttons and also displays variants if avaialble. 
 */
@Component({
  selector: 'app-product-tile',
  templateUrl: './product-tile.component.html',
  styleUrls: ['./product-tile.component.scss']
})
export class ProductTileComponent implements OnInit, OnChanges {
  onProductTile: boolean;
  showCarousel: boolean = false;
  imagesList: Array<carouselElement> = [];
  priceRoundOffInteger: string = '1.2';
  allowWishlist: boolean = false;
  allowCart: boolean = false;
  authorName: string = "Marie";
  todaysDate: Date = new Date();
  showSaving: number;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public loginDialogService: LsDialogService<LoginComponent>,
    private cartService: CartService,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
    private cartCountService: CartCountService
  ) {
    this.onProductTile = false;
  }

  @Input('tile-data') data: ProductModel = new ProductModel();
  @Output() addToCartWishlist: EventEmitter<any> = new EventEmitter<any>();
  @Output() addToCart: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  set view(viewVal: string) {
    this.onProductTile = (viewVal == "list");
  }
  prodData: ProductModel = new ProductModel()
  productName: string
  currType: string = "INR"
  target: string = ''
  sizeRec: string = ''
  variantData: Array<{ variantHeading: string, variantArr: Array<{ variantName: string, variantId: Array<number> }> }> = []
  defaultPath = TemplateConstants.defaultImgPath;
  ratingValue: number

  variantTypes: Array<{ variantHeading: string, options: Array<{ option: string, mappedUniqueIds: Array<number> }> }> = []
  variants: Array<ProductModel> = new Array()
  showVariants: boolean = false
  defaultClass: boolean = true

  currUser: UserModel
  cartProductMdel = new UserCartDetailDto();
  currUserEmail
  currTitleId
  currVariantId

  queryParams
  isMobile: boolean = false
  isDesktop: boolean = true
  isTab: boolean = false;
  carouselTimeOut = 1500;
  imageMap: Array<{ varId: string, images: Array<string> }> = [];
  selectedFilterIdx: number = 0;
  showVarImg: boolean = false;
  varImageUrl: string;
  constants = Constants;

  /**
   * product-data is provided as input in this component from collection-page. Populates product details in product-tile.
   * If variants are available, then populate first variant's details in product-tile.
   * fetch currency-type, general-settings, cart-settings of store, so that settings can be applied.
   */
  ngOnInit() {
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Mobi/i.test(window.navigator.userAgent)) {
      this.isMobile = true
      this.isTab = false
      this.isDesktop = false
    } else if (/Mobi|Tablet|iPad|kindle/i.test(window.navigator.userAgent)) {
      this.isMobile = false
      this.isTab = true
      this.isDesktop = false
    } else {
      this.isMobile = false
      this.isTab = false
      this.isDesktop = true
    }
    if (this.data) {
      if (this.data.imageMap) {
        Object.keys(this.data.imageMap).forEach((ele) => {
          let varId;
          varId = ele.split("_")[0];
          this.imageMap.push({
            varId: varId,
            images: this.data.imageMap[ele]
          });
        });
      }
      if (this.data.variantList && this.data.variantList.length > 0) {
        this.prodData = this.data.variantList[0]
      } else {
        this.prodData = this.data
      }
      this.productName = this.data.productName;
      this.showSaving = this.data.showSaving;
    }
    if (isPlatformBrowser(this.platformId)) {
      if (window.localStorage.getItem('currentUser') != null) {
        this.currUser = JSON.parse(window.localStorage.getItem('currentUser'));
        this.currUserEmail = this.currUser.email;
      }
      if (window.sessionStorage.getItem('currencyId') != null) {
        let currId = JSON.parse(window.sessionStorage.getItem('currencyId'));
        let currMap = JSON.parse(window.sessionStorage.getItem('currencyMap'));
        currMap.forEach(element => {
          if (element.id == currId) {
            this.currType = element.code;
          }
        });
      }
      if (Constants.ONLINE_STORE_SETTING_MODEL != undefined) {
        if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO != undefined) {
          if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 1) {
            this.priceRoundOffInteger = '1.0'
          } else if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 0) {
            this.priceRoundOffInteger = '1.2'
          }
        }
      }
      if (Constants.CART_SETTINGS != undefined) {
        if (Constants.CART_SETTINGS.allowCart != undefined) {
          if (Constants.CART_SETTINGS.allowCart == 1)
            this.allowCart = true;
          else if (Constants.CART_SETTINGS.allowCart == 0)
            this.allowCart = false;
        }
        if (Constants.CART_SETTINGS.allowWishList != undefined) {
          if (Constants.CART_SETTINGS.allowWishList == 1)
            this.allowWishlist = true;
          else if (Constants.CART_SETTINGS.allowWishList == 0)
            this.allowWishlist = false;
        }
      }
    }
  }

  highlightText(prodName) {
    var findText = 'on';
    var word = document.getElementById('productName');
    // var text: string = word.firstChild.nodeValue;
    console.log("text", word, prodName);
    // if(text.indexOf(findText)!=-1){
    //   var target = text.substr(text.indexOf(findText), text.indexOf(findText)+findText.length); 
    //   console.log("index", text.indexOf('is'), target, text.indexOf(findText)+findText.length);
    // }
    var ansTag = document.createElement('p');
    var newText = document.createTextNode(this.productName);
    ansTag.appendChild(newText);
    return ansTag;
  }

  /**
   * Whever changes are detected in component, update product-details to be populated in product-tile and 
   * in case of variants are available, then populate detail of first variant.
   */
  ngOnChanges() {
    this.showVariants = true;
    this.setUrl()
    this.getVariants()
    if (this.data != undefined) {
      this.variants = this.data.variantList
      this.ratingValue = this.data.productRatingAvg
      if (this.data.variantList != undefined && this.data.variantList.length > 0 && this.data.variantAttrMap) {
        this.showVariants = true
        let x = this.data.variantAttrMap[Object.keys(this.data.variantAttrMap)[0]]
        let y = x[Object.keys(x)[0]][0]
        let variant: ProductModel
        for (var index = 0; index < this.data.variantList.length; index++) {
          var element = this.data.variantList[index];
          if (element.id == y) {
            variant = this.data.variantList[index]
            break;
          }
        }
        if (variant) {
          if (variant.id)
            this.currVariantId = variant.id;
          if (variant.imageUrl != undefined && variant.imageUrl != "") {
            this.data.imageUrl = variant.imageUrl
          }
          if (variant.mrp != undefined) {
            this.data.mrp = variant.mrp
          }
          if (variant.salePrice != undefined) {
            this.data.salePrice = variant.salePrice
          }
        }
      }
    }
  }

  /**
   * When suggestion-option is selected, page redirects to navigation-url (detail-page of product) of option. 
   * In case of multi-store, user is naviagted to url '/store/store-domain-name/*' proceeded with product's detail page url.
   */
  setUrl() {
    if (this.data != undefined) {
      let url: string = this.data.productURLText != undefined ? this.data.productURLText : '';
      if (Constants.IS_MULTI_STORE && this.cartCountService.getSelectedStoreDomain()) {
        let storeDomain = this.cartCountService.getSelectedStoreDomain();
        this.target = 'store' + '/' + storeDomain + '/' + 'details/' + url;
      } else {
        this.target = 'details/' + url;
      }
      this.queryParams = { 't': this.data.titleId };
    }
  }

  /**
   * Fetches and prepares variants array for the product to be populated in product-tile.
   */
  getVariants() {
    if (this.data == undefined)
      return
    if (this.data.variantAttrMap != undefined) {
      let tempVariantArr = this.data.variantAttrMap;
      Object.keys(tempVariantArr).forEach(key => {
        let filters = tempVariantArr[key]
        let filtersArr: Array<{ option: string, mappedUniqueIds: Array<number> }> = []
        Object.keys(filters).forEach(element => {
          let idxArr: Array<number> = []
          filters[element].forEach(idxValue => {
            idxArr.push(idxValue);
          });
          filtersArr.push({
            option: element,
            mappedUniqueIds: idxArr
          })
        })
        this.variantTypes = new Array()
        this.variantTypes.push({
          variantHeading: key,
          options: filtersArr
        })
      })
      this.variantTypes.slice(0, 1)
    }
  }

  /**
   * When customer selected another variant, then update selected variant's details on product-tile.
   * @param event 
   * @param variantId 
   * @param idx 
   */
  setProductVariant(event, variantId, idx) {
    this.defaultClass = false;
    this.selectedFilterIdx = idx;
    let variant: ProductModel = new ProductModel();
    this.variants.forEach(element => {
      if (element.id == variantId) {
        variant = element
      }
    });
    if (variant.imageUrl != undefined && variant.imageUrl != "") {
      this.data.imageUrl = variant.imageUrl
    }
    if (variant.mrp != undefined) {
      this.data.mrp = variant.mrp
    }
    if (variant.salePrice != undefined) {
      this.data.salePrice = variant.salePrice
    }
    this.queryParams = { 't': this.data.titleId, 'sizeRecNum': variant.id }
    this.currVariantId = variant.id;
    this.updateImagesByVarId();
  }

  /**
   * prepares image-array to be populated in image-carousel.
   * @param images 
   */
  updateImagesCarousel(images: Array<string>) {
    this.imagesList = [];
    if (this.data && this.data.imageUrl) {
      let prefixUrl = this.data.imageUrl;
      if (images && images.length > 1) {
        this.showVarImg = false;
        for (let i = 0; i < images.length; i++) {
          this.imagesList.push({ imageUrl: prefixUrl + 'm/' + images[i], title: '' });
        }
      } else if (images && images.length == 1) {
        this.varImageUrl = prefixUrl + 'm/' + images[0];
        this.showVarImg = true;
        this.showCarousel = false;
      }
    }
    if (!this.showCarousel && this.imagesList && this.imagesList.length > 1) {
      this.showCarousel = true;
      this.showVarImg = false;
    }
  }

  /**
   * Updates image-carousel when another variant gets selected.
   */
  updateImagesByVarId() {
    let imgsFound: boolean = false;
    for (var index = 0; index < this.imageMap.length; index++) {
      var element = this.imageMap[index];
      if (element.varId == this.currVariantId) {
        imgsFound = true
        this.updateImagesCarousel(element.images);
        break;
      }
    }
    if (!imgsFound) {
      this.varImageUrl = this.defaultPath;
      this.showVarImg = true;
      this.showCarousel = false;
    }
  }

  /**
   * When mouse enters the product-tile, then show image-carousel.
   */
  mouseEnter() {
    if (!this.showCarousel && !this.isMobile) {
      if (this.data && this.data.variantList && this.data.variantList.length > 1) {
        if (this.data.imageMap) {
          if (this.imageMap && this.imageMap.length > 0) {
            this.updateImagesByVarId();
          }
        }
      } else {
        if (this.data && this.data.images && this.data.images.length > 0) {
          this.updateImagesCarousel(this.data.images);
        }
      }
    }
  }

  /**
   * When mouse leaves the product-tile, then hide image-carousel.
   */
  mouseLeave() {
    if (this.showCarousel && !this.isMobile) {
      this.imagesList = [];
      this.showCarousel = false;
    }
    if (!this.showCarousel && this.showVarImg) {
      this.showVarImg = false;
    }
  }
  
  /**
   * Adds product to wishlist if user is logged-in, else first open login pop-up and after sucessful login, add product to wishlist.
   * @param event : product that needs to be added to the wishlist.
   */
  addToWishlist() {
    if (window.localStorage.getItem('currentUser') !== null) {
      this.addToCartWishlistUtility();
    } else {
      this.loginDialogService.open(LoginComponent, { panelClass: 'loginPopUp' }, null).subscribe(response => {
        if (window.localStorage.getItem('currentUser') !== null) {
          this.addToCartWishlistUtility();
        }
      });
    }
  }

  /**
   * Adds a product to the wishlist.
   * First, get the current wish-list from local-storage,
   * then, check that if product is already present in the wish-list or not.
   * if not already present, push product in the beginning of the exisiting product-list, and call method for compute-cart and update local-wishlist.
   * else show error-msg.
   */  
  addToCartWishlistUtility() {
    let msg = 'Added to your Wishlist!';
    let errMsg = 'Error in adding product to wishlist!';
    let localWishlistCart: CartModel;
    if (localStorage.getItem('wishlist')) {
      localWishlistCart = JSON.parse(localStorage.getItem('wishlist'));
    }
    let newProd: CartProductModel = new CartProductModel();
    newProd.titleId = Number.parseInt(this.data.titleId);
    newProd.numCopies = 1;
    let newWishlistCart: CartModel = new CartModel();
    newWishlistCart.cartProductModels = [];
    newWishlistCart.cartProductModels.push(newProd);
    newWishlistCart.cartWishListType = Constants.CART_WISHLIST_TYPE.WISHLIST;
    if (localWishlistCart && localWishlistCart.cartProductModels && localWishlistCart.cartProductModels.length > 0) {
      localWishlistCart.cartProductModels.forEach((ele: CartProductModel) => {
        newWishlistCart.cartProductModels.push(ele);
      });
    }
    if (!this.checkProductAlreadyPresent(parseInt(this.data.titleId), true)) {
      this.cartService.computeCart(newWishlistCart, (resp) => {
        if (!resp.error && resp.data && resp.data[0]) {
          this.setLocalCart(resp.data[0], true);
          this.cartCountService.updateWishlistCart(resp.data[0]);
          this.okayMessageDialogService.open(OkayMessageComponent, {}, msg);
        }
      });
    } else {
      this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
    }
  }
  
  /**
   * update shopping-cart or wish-list in local-storage according to the CartModel.
   * @param cart : CartModel which needs to be updated.
   * @param isWishlist : flag, if cart is shopping-cart or wishlist.
   */
  setLocalCart(cart: CartModel, isWishlist: boolean) {
    this.cartCountService.setLocalCart(cart, isWishlist);
  }

  /**
   * checks a product is already present in shopping-cart or in wish-list.
   * In multi-store case, it fetches local-shopping-cart for the selected store.
   * @param id : titleID of product.
   * @param isWishlist : flag where to check for product, in shopping-cart or in wish-list.
   * @returns boolean : true, if product is already present in shopping-cart or in wishlist, else returns false.  
   */
  checkProductAlreadyPresent(id: number, isWishlist: boolean): boolean {
    let localCart;
    if (isWishlist) {
      localCart = JSON.parse(localStorage.getItem('wishlist'));
    } else {
      localCart = this.cartCountService.getLocalCartForStore();
    }
    let present: boolean;
    if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
      for (let index = 0; index < localCart.cartProductModels.length; index++) {
        var element = localCart.cartProductModels[index];
        if (element.titleId == id) {
          present = true;
          break;
        }
      }
    }
    if (present) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * When customer tries to add product from product-tile, then this components emit event to parent component,
   * which adds the product into shopping-cart.
   */
  addToCartProduct() {
    this.addToCart.emit(this.data.titleId);
  }

  /**
   * This method gets triggered when user clicks on pre-order button.
   * If user in not logged-in, then first open login pop-uop, and after successful login, it perofrms pre-order functionality.
   * @param titleId 
   */
  addPreOrderProduct(titleId: number) {
    if (window.localStorage.getItem('currentUser') !== null) {
      this.addPreOrderUtility(titleId);
    } else {
      this.loginDialogService.open(LoginComponent, { panelClass: 'loginPopUp' }, null).subscribe(response => {
        if (window.localStorage.getItem('currentUser') !== null) {
          this.addPreOrderUtility(titleId);
        }
      });
    }
  }
 
  /**
   * This method is used for pre-orderering a product.
   * @param titleId 
   */  
  addPreOrderUtility(titleId: number) {
    let errMsg = 'Some error occurred!';
    if (titleId) {
      this.cartService.addPreOrder(titleId, (resp) => {
        if (!resp.error) {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, resp.msgList[0]);
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, errMsg);
        }
      });
    } else {
      this.okayMessageDialogService.open(OkayMessageComponent, {}, errMsg);
    }
  }

  /**
   * whenever image error event triggers, a default image is set, and make onerror event null, so that it doesn't trigger again.
   * @param event : image error event
   */
  imgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }
  
  /**
   * scrolls up to top of the page.
   */
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}

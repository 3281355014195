import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router'
import { Constants } from '../../../common/constants/lsnetx.constants'
import { ProductDetailsService } from '../../../services/product-details.service'
import { ProductModel } from '../../../common/models/productModel'
import { CategoryListPageService } from '../../../services/categoryListPage.service'
import { CartCountService } from '../../../services/cart-count.service';
import { filter } from 'rxjs/operators';

/**
 * This component displays breadcrumbs (user navigation history)
 */
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

  itemList: Array<{ pathName: string, url: string }> = [];
  productDetail: ProductModel;
  categoryMap: Array<{ pathName: string, url: string }> = [];
  currentUrl: string;
  url: string;
  _productSubscription;
  _routeSubscription;

  constructor(
    private router: Router,
    private productDetailsService: ProductDetailsService,
    private cartCountService: CartCountService
  ) { }

  /**
   * subscribes to router-events for any detecting every routing-event change.
   * filters-out url apart from query-parameters from current-url.
   */
  ngOnInit() {
    var routeNavFlag = false;
    this._routeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    )
    .subscribe((data: NavigationStart) => {
      routeNavFlag = true;
      this.currentUrl = data.url;
      if (data.url.includes("?")) {
        this.currentUrl = data.url.split("?")[0];
      }
      this.setItemList(this.currentUrl);
    });
    if (!routeNavFlag) {
      this.currentUrl = this.router.url;
      if (this.router.url.includes("?")) {
        this.currentUrl = this.router.url.split("?")[0];
      }
      this.setItemList(this.currentUrl);
    }
  }
  
  /**
   * unsubscribe all subscriptions when component destroys.
   */
  ngOnDestroy() {
    if (this._productSubscription) {
      this._productSubscription.unsubscribe();
    }
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
  }

  setItemList(data) {
    this.itemList = [];
    let ele: string
    let crumbs = data.split("/")

    crumbs.forEach(ele => {
      if (ele == "" || ele == Constants.WAR_NAME) {
        let homeIdx = this.itemList.findIndex(ele => ele.pathName == 'Home');
        if (homeIdx == -1) {
          this.itemList.push({
            pathName: 'Home',
            url: ''
          });
        }
      } else if (ele.includes('details')) {
        this._productSubscription = this.productDetailsService.productDetailObservable.subscribe((detail) => {
          this.itemList = [];
          this.itemList.push({
            pathName: 'Home',
            url: ''
          });
          if (data.includes('store/') && Constants.IS_MULTI_STORE && this.cartCountService.getSelectedStoreDomain()) {
            this.itemList.push({
              pathName: this.cartCountService.getSelectedStoreDomain(),
              url: '/store/' + this.cartCountService.getSelectedStoreDomain()
            });
          }
          this.productDetail = detail;
          if (this.productDetail.categoryMap != undefined) {
            this.categoryMap = [];
            Object.keys(this.productDetail.categoryMap).forEach(key => {
              this.categoryMap.push({
                pathName: key,
                url: this.productDetail.categoryMap[key]
              });
            })
            if (this.categoryMap) {
              this.categoryMap.forEach(value => {
                this.itemList.push({
                  pathName: value.pathName,
                  url: value.url
                });
              });
            }
            if (this.productDetail != undefined) {
              this.itemList.push({
                pathName: this.productDetail.productName,
                url: ''
              });
            }
          }
        })
      } else if (ele.includes('collections')) {
        let crumbs = data.split("/");
        let collectionIdx = crumbs.findIndex(ele => ele == 'collections');
        for (let crumbsIdx = collectionIdx + 1; crumbsIdx < crumbs.length; crumbsIdx++) {
          let collectionUrl: string = '';
          let j = crumbsIdx;
          while (j > collectionIdx) {
            if ((collectionUrl != 'collections' && crumbs[j] != 'collections') && collectionUrl != undefined)
              collectionUrl = crumbs[j] + '/' + collectionUrl;
            j--;
          }
          this.itemList.push({
            pathName: this.replaceHyphon(crumbs[crumbsIdx]),
            url: collectionUrl
          });
        }
      } else if (ele.includes('login')) {
        this.itemList.push({
          pathName: 'Login',
          url: ele
        });
      }
      else if (ele.includes('my-dashboard')) {
        this.itemList.push({
          pathName: 'My Dashboard',
          url: '/my-dashboard/profile'
        });
      } else if (ele.includes('store')) {
        if (this.cartCountService.getSelectedStoreDomain()) {
          let url = '/store/' + this.cartCountService.getSelectedStoreDomain();
          this.itemList.push({
            pathName: this.cartCountService.getSelectedStoreDomain(),
            url: url
          });
        }
      }
      else {
        if (!(data.includes('details') || (data.includes('collections')) || (data.includes('store')))) {
          this.itemList.push({
            pathName: this.replaceHyphon(ele),
            url: ele
          });
        }
      }
    });
  }

  replaceHyphon(str: string): string {
    return str.split('-').join(' ');
  }

  /**
   * 
   * @param url - 
   * @param index 
   */
  goToRelativePage(url: string, index) {
    if (this.currentUrl.includes('/details')) {
      if (url == '' || url.includes('store/')) {
        this.router.navigate([url]);
      } else {
        if (this.categoryMap != undefined) {
          let route = '';
          if (Constants.IS_MULTI_STORE && this.cartCountService.getSelectedStoreDomain()) {
            route = '/' + 'collections' + '/' + this.categoryMap[index - 2].url;
            route = '/store/' + this.cartCountService.getSelectedStoreDomain() + route;
          } else {
            route = '/' + 'collections' + '/' + this.categoryMap[index - 1].url;
          }
          this.router.navigate([route]);
        } else {
          this.router.navigate([url]);
        }
      }
    } else if (this.currentUrl.includes('/collections')) {
      if (url == '' || url.includes('store/')) {
        this.router.navigate([url]);
      } else {
        let route: string = '/' + 'collections' + '/' + url;
        if (Constants.IS_MULTI_STORE && this.cartCountService.getSelectedStoreDomain()) {
          route = '/store/' + this.cartCountService.getSelectedStoreDomain() + route;
        }
        this.router.navigate([route]);
      }
    } else {
      this.router.navigate([url]);
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HomePageService } from '../../../services/home-page.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { Constants } from '../../../common/constants/lsnetx.constants';

/**
 * unsubscribe-news-letter components - contains form, by which a user can unsubscribe the subscribes news-letter for a store.
 * @class UnsubscribeNewsLetterComponent
 */

@Component({
  selector: 'app-unsubscribe-news-letter',
  templateUrl: './unsubscribe-news-letter.component.html',
  styleUrls: ['./unsubscribe-news-letter.component.scss']
})
export class UnsubscribeNewsLetterComponent implements OnInit {

  lbl1 = "Please enter your email address";
  lbl2 = "We are sorry to find out that you are no longer interested in our newsletter. To help us improve our services, we would be grateful if you could tell us why";
  unsubscribeForm: FormGroup;
  errorMsg;

  constructor(
    private formBuilder: FormBuilder,
    private homePageService: HomePageService,
    private okayMessageService: LsDialogService<OkayMessageComponent>,
    private router: Router
  ) {
    this.unsubscribeForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      reason: ['', [Validators.required]]
    })
  }

  ngOnInit() {
  }

  /**
   * formSubmit method - unsubscribe the subscribed news-letter for a store.
   * @method formSubmit
   */
  formSubmit(event, errorLabel) {
    if (this.unsubscribeForm.valid) {
      var email = this.unsubscribeForm.value.email;
      var reason = this.unsubscribeForm.value.reason;
      this.homePageService.unsubscribeNewsletter(email, reason, (resp) => {
        if (!resp.error) {
          this.okayMessageService.open(OkayMessageComponent, {}, 'Succesfully Unsubscribed!').subscribe(response => {
            this.navigateToHome();
          })
        } else {
          this.okayMessageService.open(OkayMessageComponent, {}, resp.msgList[0]);
        }
      })
    } else {
      this.okayMessageService.open(OkayMessageComponent, {}, 'Kindly fill mandatory fields!');
    }
  }

  /**
   * redirects to home
   * For multi-store, redirect user to 'store/<current store domain>',
   * Else, redirect user to ' ' (empty) route
   */
  navigateToHome() {
    if (Constants.IS_MULTI_STORE && this.homePageService.getSelectedStoreDomain()) {
      let storeHomeUrl = '/store/' + this.homePageService.getSelectedStoreDomain();
      this.router.navigate([storeHomeUrl]);
    } else {
      this.router.navigate(['']);
    }
  }

}

<header *ngIf="!checkOutVisibility">
  <div id="trackDtls" *ngIf="!loggedIn" (click)="showTrackPopOver()">
    <p class="track-order">Track Order</p>
  </div>
  <div fxLayout="column" class="desktop-layout">
    <div class="header-top">
      <div class="defaultWidth">
        <div class="headerInner">
          <div id="companyLogo">
            <a [routerLink]="''" style="height:100%; width:100%; position:relative;" *ngIf="constants?.COMPANY_DETAILS">
              <img [src]="constants?.COMPANY_DETAILS?.companyLogo ? constants?.COMPANY_DETAILS?.companyLogo:defaultPath " onError="this.src = 'assets/images/defaultLogo.png'"
                title="{{constants?.COMPANY_DETAILS?.companyName}}" alt="{{constants?.COMPANY_DETAILS?.companyName}}">
            </a>
          </div>
          <div id="logo" class="center-position" *ngIf="(constants.IS_MULTI_STORE && isStoreSelected) || !constants.IS_MULTI_STORE" >
            <a (click)="navigateToHome()" style="height:100%; width:100%; position:relative;" *ngIf="companyDetails">
              <img [src]="companyDetails?.storeLogo ?companyDetails?.storeLogo:defaultPath " onError="this.src = 'assets/images/defaultLogo.png'"
                title="{{companyDetails?.storeName}}" alt="{{companyDetails?.storeName}}">
            </a>
          </div>
          <div class="pnlCartOuter">
            <div *ngIf="(constants.IS_MULTI_STORE && isStoreSelected) || !constants.IS_MULTI_STORE" class="cart"
              fxLayoutAlign="center center" (click)="openCart()" [ngClass]="{'hide': !allowCart}">
              <div class="shopping-cart"></div>
              <div class="cartCntPnl">
                <p class="cart-count">{{cartCount}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-bottom">
      <div class="defaultWidth header-bottom-innner">
        <div class="menuLinksPanel">
          <ul class="menuLinkItr" *ngIf="(constants.IS_MULTI_STORE && isStoreSelected) || !constants.IS_MULTI_STORE">
            <li class="floatedLeft menuLink">
              <div class="category">
                <div class="catMenuPnl categories">
                  <ul *ngIf="categoryList != undefined">
                    <li *ngFor="let category of categoryList; let i = index;" class="parentCat" (mouseover)="selectParentCat(i)">
                      <div id="itrCat-0">
                        <a attr.categoryid={{category?.categoryId}} class="lnkCatName" id="lnkCatName" [routerLink]="category?.categoryURL | urlFormatter : constants?.ULR_FORMATTER?.COLLECTION">
                          <h1 [ngClass]="{'selectedCat': i == selectedParentCat}">{{category?.categoryName}}</h1>
                        </a>
                      </div>
                      <div class="fetch-categories" [ngClass]="{'show-cat': i == selectedParentCat}">
                        <ul *ngIf="category?.categoryModels != undefined" class="itrCat">
                          <li *ngFor="let subCat of category?.categoryModels; let catLvl1Idx = index;" (mouseover)="selectCatLvl1(catLvl1Idx)">
                            <div id="itrCat-1">
                              <a attr.categoryid={{subCat?.categoryId}} [ngClass]="{'selectedCat': i == selectedParentCat &&  catLvl1Idx == selectedCatLvl1}" class="lnkCatName" id="lnkCatName" [routerLink]="subCat?.categoryURL | urlFormatter : constants?.ULR_FORMATTER?.COLLECTION"
                                style="position:relative">
                                {{subCat?.categoryName}}
                              </a>
                              <div class="fetch-categories">
                                <ul *ngIf="subCat?.categoryModels != undefined" class="itrCat secCat">
                                  <li *ngFor="let secLevel of subCat?.categoryModels" class="secLevel">
                                    <div id="itrCat-2">
                                      <a attr.categoryid={{secLevel?.categoryId}} class="lnkCatName" id="lnkCatName"
                                        [routerLink]="secLevel?.categoryURL | urlFormatter : constants?.ULR_FORMATTER?.COLLECTION">
                                        {{secLevel?.categoryName}}
                                      </a>
                                      <ul *ngIf="secLevel?.categoryModels != undefined" class="itrCat thirdCat">
                                        <li *ngFor="let thirdLevel of secLevel?.categoryModels" class="thirdLevel">
                                          <div id="itrCat-3">
                                            <a attr.categoryid={{thirdLevel?.categoryId}} class="lnkCatName" id="lnkCatName"
                                              [routerLink]="thirdLevel?.categoryURL | urlFormatter : constants?.ULR_FORMATTER?.COLLECTION">
                                              {{thirdLevel?.categoryName}}
                                            </a>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="rightPnl">
          <div class="search-panel" (click)="openSearch()" *ngIf="(constants.IS_MULTI_STORE && isStoreSelected) || !constants.IS_MULTI_STORE">
            <a>Search</a>
          </div>
          <div class="viewSearch" *ngIf="viewSearch && ((constants.IS_MULTI_STORE && isStoreSelected) || !constants.IS_MULTI_STORE)"
            (click)="closeSearch()">
            <div fxLayoutAlign="center center" fxFlexFill>
              <app-search (closeSearchEvent)="closeSearch()"></app-search>
            </div>
          </div>
          <div class="login-pnl">
            <div *ngIf="loggedIn" id="pnlLoginDtls" class="displayLoginDtls">
              <p id="lblUserName" class="lblUserName" [innerHTML]="userState.userName !=null ? userState.userName + '!' : userState.fName + '!'"></p>
              <span class="fa fa-caret-down"></span>
              <div id="dashOptionPnl" tabindex="1">
                <a routerLink="my-dashboard/profile" routerLinkActive="active">My Profile</a>
                <a routerLink="my-dashboard/orders" routerLinkActive="active">My Orders</a>
                <a routerLink="my-dashboard/transactions" routerLinkActive="active">Transaction Log</a>
                <a routerLink="my-dashboard/change-password" routerLinkActive="active">Change Password</a>
                <a *ngIf="((constants.IS_MULTI_STORE && isStoreSelected) || !constants.IS_MULTI_STORE) && allowWishlist"
                  routerLink="my-dashboard/wishlist" routerLinkActive="active">My Wishlist&nbsp;({{wishlistCount}})</a>
                <a id="lnk_login" class="signInDtl" (click)="logOut()">Sign Out</a>
              </div>
            </div>
            <div *ngIf="!loggedIn" (click)="onOpen()">
              <a>Sign In</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SuggestionOption } from '../../../LSNG/components/ls-suggestion-field/suggestion-option';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { CartCountService } from '../../../services/cart-count.service';

@Component({
  selector: 'app-test-suggestion',
  template: `
  <div>
    <a class="hello" href="{{url}}">{{label}}</a>
    </div>

  `,
  styleUrls: ['./search.component.scss']
})
export class SuggestionOptionComponent implements OnInit, SuggestionOption {

  built = false;
  label = "";
  value = "";
  json: any;
  url = ""

  constructor(
    private _cdr: ChangeDetectorRef,
    private cartCountService: CartCountService
  ) { }

  ngOnInit() {
  }

  buildComponent(json: any): void {
    this.built = true;
    this.label = json.label;
    this.value = json.value;
    this.initializeData();
    this._cdr.detectChanges();
  }

  initializeData() {
    this.url = 'details/' + this.value;
    if (Constants.IS_MULTI_STORE && this.cartCountService.getSelectedStoreDomain()) {
      this.url = '/store/' + this.cartCountService.getSelectedStoreDomain() + '/' + this.url;
    }
  }

}

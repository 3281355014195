import { Component, OnInit } from '@angular/core';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';

/**
 * This component is displayed as 'yes/No' pop-up which asks user for further action ('yes/no').
 */
@Component({
  selector: 'app-yes-no-popup',
  templateUrl: './yes-no-popup.component.html',
  styleUrls: ['./yes-no-popup.component.scss']
})
export class YesNoPopupComponent implements OnInit {

  /**
   * Mesasge to be displayed
   */
  msg;
  /**
   * dialog instance
   */
  dialog: LsMdDialog<YesNoPopupComponent>
  /**
   * data provided to this component when opended as pop-up.
   */
  dataList;

  constructor() { }

  /**
   * fetch message from dataList and display it. 
   */
  ngOnInit() {
    this.msg = this.dataList;    
  }

  /**
   * When user clicks on 'yes' button, then send 'yes' as string while closing pop-up.
   * @param event - @de
   */
  yesClicked(event: Event){
    this.dialog.close("yes"); 
  }

  /**
   * When user clicks on 'no' button, then send 'no' as string while closing pop-up.
   * @param event - @de
   */
  noClicked(event: Event){
    this.dialog.close("no");
  }

}

<div class="dashboard-options">
    <div class="user-name-panel">
        <span class="user-icon"></span>
        <p class="user-name" [innerHTML]="'Hi  ' + userName"></p>
        <span class="close-pop-up" (click)="onClick()"></span>
    </div>
    <div class="dashboard-content">
        <a href="my-dashboard/profile">My Profile</a>
        <a href="my-dashboard/orders">My Orders</a>
        <a href="my-dashboard/transactions">Transaction Log</a>
        <a href="my-dashboard/change-password">Change Password</a>
        <a *ngIf="(constants.IS_MULTI_STORE && isStoreSelected) || !constants.IS_MULTI_STORE" href="my-dashboard/wishlist">My Wishlist</a>
        <p (click)="logOut()">Sign Out</p>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackOrderService } from '../../../services/track-order.service';
import { OrderModel } from '../../../common/models/orderModel';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { QueryComponent } from '../../../published/commonComponent/query/query.component';
import { TemplateConstants } from '../../commonComponent/template-constants';
import { CartCountService } from '../../../services/cart-count.service';
import { HomePageService } from '../../../services/home-page.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';

/**
 * components which shows details of order, when user tracks order.
 * @class OrderTrackingComponent
 */
@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.scss']
})
export class OrderTrackingComponent implements OnInit {
  /**
   * @var order: contains order details
   */
  order: OrderModel
  /**
   * @var const: contains all constants
   */
  const = Constants
  /**
   * @var showLeft: flag for left tab display
   */
  showLeft: boolean;
  /**
   * @var showRight: flag for right tab display 
   */
  showRight: boolean;
  showDetails = [];
  /**
   * @var currType: stores
   */
  currType: string = "INR";
  /**
   * @var noOrdersFlag: flag for no orders
   */
  noOrdersFlag: boolean = false;
  /**
   * @var defaultPath: stores default image path for product's image
   */
  defaultPath = TemplateConstants.defaultImgPath;

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private trackOrderService: TrackOrderService,
    private cartCountService: CartCountService,
    private homePageService: HomePageService,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>
  ) { }

  /**
   * fetch order details
   * initialize order-detail view
   * @method ngOnInit
   */
  ngOnInit() {
    this.getOrderDetails();
    this.showLeft = true
  }

  /**
   * @method toggleShowDetails: deprecated 
   */
  toggleShowDetails(index) {
    this.showDetails[index] = !this.showDetails[index];
  }

  /**
   * fetches order details by using emailId and orderId from current url's query params,
   * populate order details.
   * @method getOrderDetails
   */
  getOrderDetails() {
    let email = this.activatedRoute.snapshot.queryParamMap.get("email").toString();
    let orderId = this.activatedRoute.snapshot.queryParamMap.get('orderId').toString();
    this.trackOrderService.trackOrderUsingOrderId(email, orderId, (data) => {
      if (data != undefined && !data.error && data.data != undefined) {
        this.order = data.data[0];
      } else if (data != undefined && data.error) {
        this.noOrdersFlag = true;
      }
    });
  }

  /**
   * shows left tab
   * @method leftClick
   */
  leftClick() {
    this.showLeft = true
    this.showRight = false;
  }

  /**
   * shows right tab
   * @method rightClick
   */
  rightClick() {
    this.showRight = true;
    this.showLeft = false;
  }

  /**
  * whenever image error event triggers, a default image is set, and make onerror event null, so that it doesn't trigger again.
  * @method imgErrorHandler
  * @param event : image error event
  */
  imgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

  /**
   * redirects to home
   * For multi-store, redirect user to 'store/<current store domain>',
   * Else, redirect user to ' ' (empty) route
   */
  navigateToHome() {
    if (Constants.IS_MULTI_STORE && this.cartCountService.getSelectedStoreDomain()) {
      let storeHomeUrl = '/store/' + this.cartCountService.getSelectedStoreDomain();
      this.router.navigate([storeHomeUrl]);
    } else {
      this.router.navigate(['']);
    }
  }

  goToProductDetailPage(product, titleId, storeId) {
    if (Constants.IS_MULTI_STORE) {
      if (this.cartCountService.getSelectedStoreDomain() && storeId != this.cartCountService.getSelectedStoreId()) {
        this.homePageService.removeSelectedStore();
      }
      let storeDomain = '';
      if (Constants.STORES && Constants.STORES.length > 0) {
        let idx = Constants.STORES.findIndex(ele => ele.storeId == storeId);
        storeDomain = Constants.STORES[idx].storeDomain;
        this.updateStore(product, titleId, storeId, storeDomain);
      } else {
        this.homePageService.getAllStores(resp => {
          if (resp) {
            if (Constants.STORES && Constants.STORES.length > 0) {
              let idx = Constants.STORES.findIndex(ele => ele.storeId == storeId);
              storeDomain = Constants.STORES[idx].storeDomain;
              this.updateStore(product, titleId, storeId, storeDomain);
            }
          } else {
            this.okayMessageDialogService.open(OkayMessageComponent, {}, "Some Error Occurred!");
          }
        })
      }
    } else {
      this.router.navigate([this.getProductUrl(product)], { queryParams: this.getQueryParamsForProduct(titleId) });
    }
  }

  updateStore(product, titleId, storeId, storeDomain) {
    this.homePageService.storeUpdation(storeId, storeDomain, (resp) => {
      if (resp) {
        this.router.navigate([this.getProductUrl(product)], { queryParams: this.getQueryParamsForProduct(titleId) });
      }
    });
  }

  /**
   * Redirects to product's detail page. 
   * In case of multi-store, user is naviagted to url '/store/store-domain-name/*' proceeded with product's detail page url.
   * @param product: ProductModel of product
   */
  getProductUrl(product) {
    let url: string = '', target: string = '';
    if (product) {
      url = product.productURLText != undefined ? product.productURLText : '';
      if (Constants.IS_MULTI_STORE && this.cartCountService.getSelectedStoreDomain()) {
        let storeDomain = this.cartCountService.getSelectedStoreDomain();
        target = '/store' + '/' + storeDomain + '/' + 'details/' + url;
      } else {
        target = '/details/' + url;
      }
    }
    return target;
  }
  
  /**
   * prepare query-parameters for product to naviagte to product's detail page.
   * @param product 
   */
  getQueryParamsForProduct(titleId) {
    let queryParams = {};
    if (titleId) {
      queryParams = { 't': titleId };
    }
    return queryParams;
  }

}

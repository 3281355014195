<div class="contact-us">
  <p class="heading">Contact Us</p>
  <div class="container">
    <div class="leftPnl">
      <div class="pnlContactTop" *ngIf="content && content != 'false'">
        <div [innerHtml]='content | safeHtml' class="static-content" *ngIf="content != 'false'"></div>
      </div>
      <form enctype="multipart/form-data" [formGroup]="contactForm" (ngSubmit)="submitContactQuery()">
        <div class="form-field">
          <p class="label">Name</p>
          <input class="txtFieldCls" type="text" formControlName="name">
        </div>
        <div class="form-field">
          <p class="label">Subject</p>
          <select class="text-field txtFieldCls" formControlName="queryOption">
            <option *ngFor="let item of subjectList" [value]="item.value">{{item.label}}</option>
          </select>
        </div>
        <div class="form-field">
          <p class="label">Email</p>
          <input class="txtFieldCls" type="email" formControlName="email">
        </div>
        <div class="form-field">
          <p class="label">Contact Number</p>
          <input class="txtFieldCls" maxlength="10" (keypress)="keyPress($event)" pattern="[0-9]*" formControlName="phoneNum">
        </div>
        <div class="form-field">
          <p class="label">Message (max. 500 characters)</p>
          <textarea class="text-area txtFieldCls" formControlName="message"></textarea>
        </div>
        <div class="attch-file">
          <p #fileName></p>
          <span #changeMenu class="attchment"> + Add Attachment</span>
          <input type="file" class="file-upload" (change)="onFileChange($event, fileName, changeMenu)">
        </div>
        <div class="btnPnl">
          <button class="submit" [ngClass]="{'disable': !contactForm.valid}" [disabled]="!contactForm.valid">Send</button>
        </div>
      </form>
    </div>
    <div class="rightPnl">
      <div class="vender-info">
        <div *ngIf="vendorDetails != undefined" class="contact-mail">
          <p class="sub-heading">Mail Us</p>
          <a [href]="'mailto:'+vendorDetails.ccMail">{{vendorDetails.ccMail}}</a>
        </div>
        <div class="contact-call">
          <p class="sub-heading">Call Us</p>
          <p>{{vendorDetails?.ccContactNo}}</p>
        </div>
        <div class="contact-office">
          <p class="sub-heading">Corporate Office</p>
          <p>{{companyDTO?.address}},{{companyDTO?.city}}</p>
          <p>{{companyDTO?.address}}</p>
          <p>{{companyDTO?.city}} - {{companyDTO?.postalCode}}</p>
          <p>{{companyDTO?.state}} - {{companyDTO?.postalCode}}</p>
          <p *ngIf="companyDTO?.countryDTO && companyDTO?.countryDTO?.countryName">{{companyDTO?.countryDTO?.countryName}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="contact-map" *ngIf="googleMapUrl">
    <iframe *ngIf="googleMapUrl" [attr.src]="googleMapUrl | safeUrl"></iframe>
  </div>
</div>
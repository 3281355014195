import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { CartCountService } from '../../../services/cart-count.service';

/**
 * This component displays form for forgot-password.
 */
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPwdForm: FormGroup

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    public loginService: LoginService,
    public dialogService: LsDialogService<OkayMessageComponent>,
    private cartCountService: CartCountService
  ) { }

  /**
   * creates form-group for forgot-password form.
   */
  ngOnInit() {
    this.forgotPwdForm = this.formBuilder.group({
      email: ["", [Validators.email, Validators.required]]
    })
  }

  /**
   * Validates forgot-password form and if form is valid, then sends forgot-password request.
   * Which on success, sends mail to user containing his/her password.
   * @param $event - no use
   */
  forgotPwdRequest($event) {
    let email = this.forgotPwdForm.value.email;
    if (email === "" || email === null) {
      this.dialogService.open(OkayMessageComponent, {}, "Id Required!").subscribe(response => {
      })
    } else {
      this.loginService.login(false, email, "", true, Constants.UserType.registered, (resp) => {
        this.forgotPwdForm.reset();
        this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
        })
      })
    }

  }
  
  /**
   * redirects to home
   * For multi-store, redirect user to 'store/<current store domain>',
   * Else, redirect user to ' ' (empty) route
   */
  navigateToHome() {
    if (Constants.IS_MULTI_STORE && this.cartCountService.getSelectedStoreDomain()){
      let storeHomeUrl = '/store/' + this.cartCountService.getSelectedStoreDomain();
      this.router.navigate([storeHomeUrl]);
    } else {
      this.router.navigate(['']);
    }
  }

}

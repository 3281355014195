import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HomePageService } from '../../../../../services/home-page.service';
import { BannerModel } from '../../../../../common/models/bannerModel';
import { carouselElement } from '../../../../../LSNG/components/ls-carousel/carousel/carousel.component';
import { Router } from '@angular/router';
import { TemplateConstants } from '../../../../commonComponent/template-constants';
import { Constants } from '../../../../../common/constants/lsnetx.constants';

/**
 * This component displays banners.
 */
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnDestroy {

  constructor(public homePageService: HomePageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) { }

  banners: Array<BannerModel> = [];
  images: Array<carouselElement> = undefined;
  defaultImages: Array<carouselElement> = new Array();
  bannerHeight = 100 + '%';
  defaultBanner = TemplateConstants.templateAssetsPath + "/images/banner/banner1.jpg";
  _homePageSubscription;

  /**
   * Fetches banners and populates it in carousel. If no banner is fetched, then populates default banners.
   */
  ngOnInit() {
    this._homePageSubscription = this.homePageService.bannersOffersObservable.subscribe((data) => {
      if (data && data.length > 0) {
        this.banners = [];
        let bannerOffers: Array<BannerModel> = data;
        bannerOffers.forEach((ele) => {
          if (ele.bannerType == Constants.BANNER_TYPE.BANNER) {
            this.banners.push(ele);
          }
        });
        if (this.banners.length > 0) {
          this.images = [];
          this.banners.forEach(element => {
            this.images.push({ imageUrl: element.imageName, title: element.altText })
          });
        } else {
          this.setDefaultBanners();
        }
      } else {
        this.setDefaultBanners();
      }
    });
  }

  /**
   * unsubscribe all subscriptions when component destroys.
   */
  ngOnDestroy() {
    if (this._homePageSubscription) {
      this._homePageSubscription.unsubscribe();
    }
  }

  /**
   * fetches default banners from assets and populates it in carousel.
   */
  setDefaultBanners() {
    this.defaultImages.push({ imageUrl: TemplateConstants.templateAssetsPath + '/images/banner/banner1.jpg', title: 'defaultBanner1' })
    this.defaultImages.push({ imageUrl: TemplateConstants.templateAssetsPath + '/images/banner/banner2.jpg', title: 'defaultBanner2' })
    this.defaultImages.push({ imageUrl: TemplateConstants.templateAssetsPath + '/images/banner/banner3.jpg', title: 'defaultBanner3' })
    this.defaultImages.push({ imageUrl: TemplateConstants.templateAssetsPath + '/images/banner/banner4.jpg', title: 'defaultBanner4' })
    this.images = this.defaultImages;
  }

  /**
   * When user clicks on any banner, then user is redirected to the url present in the BannerModel of that banner.
   * It url contains 'collections', then redirect to appropriate collection-page.
   * @param event 
   */
  naviagteToDesiredRoute(event) {
    if (this.banners[event]) {
      let href: string = this.banners[event].imageHref
      if (href != undefined && href != '' && isPlatformBrowser(this.platformId)) {
        if (href.indexOf('collections') > -1) {
          this.router.navigate(['collections' + href.split('collections')[1]])
        } else {
          window.location.href = href;
        }
      }
    }
  }

  /**
   * whenever image error event triggers, a default image is set, and make onerror event null, so that it doesn't trigger again.
   * @param event : image error event
   */
  bannerImgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultBanner;
  }

}

import { Component, OnInit, Inject, PLATFORM_ID, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, RouterEvent, ActivatedRoute, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { filter } from 'rxjs/operators';
import { LoaderService } from '../../../services/loader.service';
import { CategoryService } from '../../../services/category.service';
import { HomePageService } from '../../../services/home-page.service';
import { CategoryModel } from '../../../common/models/category-model';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit, OnDestroy {
  title = 'app';
  data;//data of dialog
  value;
  master: string;
  isDashboard: boolean = false;
  isPos: boolean = false;
  headerVisibility: boolean = true;
  breadcrumbsVisibility: boolean = true;
  showLnkTopArrow: boolean = false
  googleAnalyticsCode;
  @ViewChild('accountDashboard', {static: false}) accountDashboard: any;
  catList;
  currMap;
  _routeSubscription;
  _routeSubscription2;
  _routeSubscription3;
  _homePageSubscription;
  categoryList: Array<CategoryModel>;
  constants = Constants;
  isStoreSelected: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public loaderService: LoaderService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public categoryService: CategoryService,
    private homePageService: HomePageService

  ) {
    this._routeSubscription3 = router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    });
  }

  /**
   * Subscribes to router-events, so that when user navigates to checkout page, header and footer components can be hidden.
   * In case of multi-store, it subcribes to isStoreSelectedObservable, so that when user selects any store, it is updated and 
   * when user is navigated to empty route, then selected store is removed.
   */
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.pageYOffset > 0) {
        this.showLnkTopArrow = true;
      }
    }
    Constants.MENU_BAR_NAV = this.accountDashboard;
    this._routeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((data: NavigationStart) => {
      if (data.url == '/' || data.url == '/' + Constants.WAR_NAME) {
        this.headerVisibility = true
        this.breadcrumbsVisibility = false
      } else if (data.url.indexOf('checkout') > -1 || data.url.indexOf('pos') > -1) {
        this.headerVisibility = false
        this.breadcrumbsVisibility = false
        if (data.url.indexOf('pos') > -1) {
          this.isPos = true
        }
      } else {
        this.headerVisibility = true
        this.breadcrumbsVisibility = true
      }
    });
    if (this.constants.IS_MULTI_STORE) {
      this._homePageSubscription = this.homePageService.isStoreSelectedObservable.subscribe(isSelected => {
        if (isSelected) {
          this.isStoreSelected = true;
        } else {
          this.isStoreSelected = false;
        }
      });
      this._routeSubscription2 = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((data: NavigationEnd) => {
        if ((data.url == '' || data.url == '/' || data.url == '/' + Constants.WAR_NAME) && this.isStoreSelected) {
          this.removeSelectedStore();
        } else if (data.url.includes('store/')) {
          if (this.homePageService.getSelectedStoreId()) {
            this.isStoreSelected = true;
          } else {
            this.removeSelectedStore();
            this.router.navigate(['']);
          }
        }
      });
    }
  }

  /**
   * removes currently selected store, in case of mutli-store.
   */
  removeSelectedStore() {
    this.homePageService.removeSelectedStore();
  }
  
  /**
   * unsubscribe all subscriptions when component destroys.
   */
  ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    if (this._routeSubscription2) {
      this._routeSubscription2.unsubscribe();
    }
    if (this._homePageSubscription) {
      this._homePageSubscription.unsubscribe();
    }
    if (this._routeSubscription3) {
      this._routeSubscription3.unsubscribe();
    }
  }

  /**
   * Gets triggerd when currencyMapEvent is emmitted from HeaderComponent.
   * @param event - currencyMap emitted from child-component 'Header-Component'.
   */
  currMapHandler(event) {
    this.currMap = event
  }

  /**
   * Gets triggerd when categoryListEvent is emmitted from HeaderComponent.
   * @param event - categoryList emitted from child-component 'Header-Component'.
   */
  catgListHandler(event) {
    this.catList = event;
  }

  /**
   * whenever route changes, then display loader when navigation-start.
   * On naviagtion-cancel, end or error, hide loader.
   * @param event- router event
   */
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showLoader();
    }
    if (event instanceof NavigationEnd) {
      this.hideLoader();
    }
    if (event instanceof NavigationCancel) {
      this.hideLoader();
    }
    if (event instanceof NavigationError) {
      this.hideLoader();
    }
  }

  /**
   * displays loader on screen.
   */
  showLoader() {
    this.loaderService.show();
  }

  /**
   * hides loader from screen.
   */
  hideLoader() {
    this.loaderService.hide();
  }

  /**
   * scrolls up to top of the page.
   */
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  /**
   * window scroll event handler
   */
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.pageYOffset == 0) {
        this.showLnkTopArrow = false
      } else if (window.pageYOffset > 0) {
        this.showLnkTopArrow = true
      }
    }
  }

}

<div *ngIf="prodData != undefined" class="tileWrapper" [class.showHoverDiv]="showVariants" [class.on-product-tile]="onProductTile">
  <div class="imageWrapper" style="position:relative">
    <div class="addWishPanel hide" (click)="addToWishlist()" *ngIf="allowWishlist">
      <p>Add to Wishlist</p>
    </div>
    <a [routerLink]="'/'+target" [queryParams]="queryParams" (click)="scrollToTop()">
      <img [src]="(prodData?.imageUrl && prodData?.images && prodData?.images[0])?prodData.imageUrl+'m/'+prodData.images[0]:defaultPath "
        class="img-responsive" alt="" (error)="imgErrorHandler($event)" *ngIf="(!showCarousel) && (!showVarImg)">
      <img [src]="(varImageUrl)?varImageUrl:defaultPath" class="img-responsive" alt="" (error)="imgErrorHandler($event)"
        *ngIf="(!showCarousel) && (showVarImg)">
    </a>
  </div>
  <div class="detailDiv">
    <!-- <p id="productName"> {{productName}}</p> -->
    <a [routerLink]="'/'+target" [queryParams]="queryParams" (click)="scrollToTop()" id="productName">{{productName}}</a>
    <!-- <a [routerLink]="'/author/'+prodData?.brandUrl" id="authorName">{{prodData?.brandName}}</a> -->
    <!-- <div class="ratingDiv">
      <ls-rating [editable]=false name="ratingVal" [(ngModel)]="ratingValue" (changeRating)="changeRatingEvent($event)"></ls-rating>
    </div> -->
    <div class="ratingDiv">
      <ls-rating [editable]=false name="giveRating" [(ngModel)]="prodData.productRatingAvg"></ls-rating>
    </div>
    <div class="priceDiv">
      <div id="discountDiv" [ngClass]="{'marginBottom': onProductTile}" *ngIf="prodData.mrp > prodData.salePrice && showSaving == 1 && prodData?.discountPercent > 0">
        <p id="productMrp">
          {{prodData.mrp | currency:currType:'symbol': priceRoundOffInteger}}
        </p>
        <p id="discountLbl" *ngIf="onProductTile">{{prodData?.discountPercent | number: '1.1'}}%&nbsp; OFF</p>
      </div>
      <div id="salePriceDiv">
        <p id="productSalePrice">{{prodData.salePrice | currency:currType:'symbol': priceRoundOffInteger}}</p>
      </div>
    </div>
    <!-- <div class="buyNowPnl" (click)="addToCartProduct()" *ngIf="(prodData?.outOfStock == false && allowCart || (prodData?.outOfStock == true && allowCart && prodData?.acceptOrderOnOutOfStock == 1)) && (!prodData?.releaseDate || (prodData?.releaseDate && prodData?.releaseDate < todaysDate)) && !prodData?.preOrder">
      <a>Buy Now</a>
    </div>
    <div class="buyNowPnl" (click)="addPreOrderProduct(prodData?.titleId)" *ngIf="(prodData?.preOrder || prodData?.releaseDate && prodData?.releaseDate > todaysDate)">
      <a>Pre-Order</a>
    </div> -->
    <div class="hoverDiv">
      <div class="pnlFilterItrOuter">
        <ul class="filterItr">
          <div class="filterItrLi" *ngFor="let variantType of variantTypes; let variantTypeIdx = index;">
            <div class="pnlMandtryFieldInner">
              <p class="variantDesc">{{variantType.variantHeading}}</p>
              <ul class="variantItr">
                <li class="variantItrLi" [class.filterSelected]="(optionIdx == selectedFilterIdx)||(optionIdx == 0 && defaultClass == true)"
                  *ngFor="let variant of variantType.options; let optionIdx = index;" (click)="setProductVariant($event, variant.mappedUniqueIds, optionIdx)">
                  <p class="filter">{{variant.option}}</p>
                </li>
              </ul>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>
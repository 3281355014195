import { Component, Injector, OnInit, ContentChild, Input, ElementRef, ComponentRef, AfterViewInit, ViewChild, OnDestroy, OnChanges } from '@angular/core';
import { PopUpTriggerDirective } from './pop-up-trigger.directive';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef, OverlayConnectionPosition, OriginConnectionPosition, ComponentType, OverlayContainer, PositionStrategy } from '@angular/cdk/overlay'


export class PopupConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  positionType?: string
}

export class Position {
  overlay: OverlayConnectionPosition
  origin: OriginConnectionPosition
}

export class Offsets {
  xOffset: number
  yOffset: number
}

export interface ArrowConfig {
  showLeft: boolean
  showRight: boolean
  showTop: boolean
  showBottom: boolean
  left: string
  top: string
  bottom: string
  right: string
}

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  private createInjector(overlayRef: OverlayRef): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(OverlayRef, overlayRef);
    injectorTokens.set(PopUpComponent, this)
    return new PortalInjector(this.injector, injectorTokens)
  }

  private positions: { [key: string]: Position } = {
    'top': {
      origin: { originX: 'center', originY: 'top' },
      overlay: { overlayX: 'center', overlayY: 'bottom' }
    },
    'bottom': {
      origin: { originX: 'start', originY: 'bottom' },
      overlay: { overlayX: 'end', overlayY: 'top' }
    },
    'right': {
      origin: { originX: 'end', originY: 'top' },
      overlay: { overlayX: 'start', overlayY: 'top' }
    },
    'left': {
      origin: { originX: 'start', originY: 'top' },
      overlay: { overlayX: 'end', overlayY: 'top' }
    }
  }

  @ContentChild(PopUpTriggerDirective, {static: true}) trigger: PopUpTriggerDirective;
  @Input() component: ComponentType<{}>
  @Input('popUpConfig') config: PopupConfig
  @Input() popupHeight: string
  @Input() popupWidth: string
  @Input() data: any
  directive: PopUpTriggerDirective;
  templateScribe;
  appStateSubscription;

  DEFAULT_CONFIG: PopupConfig = {
    hasBackdrop: true,
    backdropClass: 'dark-backdrop',
    panelClass: 'ls-popup',
    positionType: 'right'
  }

  ARROW: ArrowConfig = {
    showLeft: false,
    showRight: false,
    showTop: false,
    showBottom: false,
    left: 0 + 'px',
    top: 0 + 'px',
    right: 0 + 'px',
    bottom: 0 + 'px'
  }

  OFFSET: Offsets = {
    xOffset: 0,
    yOffset: 0
  }


  constructor(
    private injector: Injector,
    private overlay: Overlay,
    private elementRef: ElementRef,
    private container: OverlayContainer,
  ) {
  }

  x: ComponentRef<any>
  private overlayRef: OverlayRef;
  open(position: string) {
    this.OFFSET.xOffset = 0;
    this.OFFSET.yOffset = 0;
    let rect = this.trigger.ele.nativeElement.getBoundingClientRect();
    switch (position) {
      case 'right':
        {
          this.ARROW.left = rect.left + rect.width + 'px';
          this.OFFSET.xOffset = 15;
          this.OFFSET.yOffset = -20;
          if (rect.top + parseInt(this.popupHeight) > window.innerHeight) {
            this.OFFSET.yOffset = window.innerHeight - (rect.top + parseInt(this.popupHeight))
          }
          this.ARROW.top = rect.top + 'px'
          this.ARROW.showLeft = true;
          break;
        }
      case 'left':
        {
          this.ARROW.left = rect.left - 50 + 'px';
          if (rect.top + parseInt(this.popupHeight) > window.innerHeight) {
            this.OFFSET.yOffset = window.innerHeight - (rect.top + parseInt(this.popupHeight))
          }
          this.ARROW.top = rect.top + 'px'
          this.ARROW.showRight = true;

          break;
        }
      case 'bottom':
        {
          this.ARROW.left = rect.left + rect.width - 47 + 'px';
          this.ARROW.top = rect.bottom + 6 + 'px';
          this.OFFSET.yOffset = 20;
          this.OFFSET.xOffset = 80;
          if (rect.left - parseInt(this.popupWidth) < 0) {
            this.OFFSET.xOffset = parseInt(this.popupWidth) - rect.left;

          }
          this.ARROW.showBottom = true;
          // console.log(this.OFFSET);
          // console.log(this.ARROW);
          // console.log(rect);
          break;
        }
    }

    let overlayConfig = this.getOverlayConfig(this.config || this.DEFAULT_CONFIG)
    this.overlayRef = this.overlay.create(overlayConfig);

    const injector = this.createInjector(this.overlayRef)
    const componentPortal = new ComponentPortal(this.component, null, injector)

    this.overlayRef.attach(componentPortal);
    this.overlayRef.backdropClick().subscribe(_ => this.close());

  }


  private getOverlayConfig(config: PopupConfig): OverlayConfig {
    const positionStrategy = this.overlay.position().flexibleConnectedTo(this.elementRef).withPositions([
      {
        originX : 'start',
        originY : 'top',
        overlayX : 'end',
        overlayY : 'top',
      },
      {
        originX : 'end',
        originY : 'bottom',
        overlayX : 'start',
        overlayY : 'bottom',
      },
      {
        originX : 'start',
        originY : 'bottom',
        overlayX : 'end',
        overlayY : 'bottom',
      },
    ])
    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });
    return overlayConfig;
  }

  private getOffsets(position): Offsets {
    if (position == 'left' || position == 'right') {
      if (this.overlayRef.overlayElement.offsetTop < 0) {
        return { xOffset: 0, yOffset: -(this.overlayRef.overlayElement.offsetTop) + 24 }
      } else if (this.overlayRef.overlayElement.offsetHeight + this.overlayRef.overlayElement.offsetTop > window.innerHeight) {
        return { xOffset: 0, yOffset: window.innerHeight - (this.overlayRef.overlayElement.offsetHeight + this.overlayRef.overlayElement.offsetTop) - 24 }
      }
      else {
        return { xOffset: 0, yOffset: 0 }
      }
    } else {
      if (this.overlayRef.overlayElement.offsetLeft < 0) {
        return { xOffset: -(this.overlayRef.overlayElement.offsetLeft) + 24, yOffset: 0 }
      } else if (this.overlayRef.overlayElement.offsetLeft + this.overlayRef.overlayElement.offsetWidth > window.innerWidth) {
        return { xOffset: window.innerWidth - (this.overlayRef.overlayElement.offsetLeft + this.overlayRef.overlayElement.offsetWidth) - 24, yOffset: 0 }
      }
      else {
        return { xOffset: 0, yOffset: 0 }
      }
    }
  }

  close(): void {
    this.ARROW.showLeft = false
    this.ARROW.showRight = false
    this.ARROW.showTop = false
    this.ARROW.showBottom = false

    // this.overlayRef.dispose();
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  ngOnInit() {
 
  }

  ngAfterViewInit() {
    if (this.trigger) {
      this.trigger.triggerEvent.subscribe(event => {
        this.open(this.config.positionType)
      })
    }
  }

  /**
   * unsubscribe all subscriptions when component destroys.
   */
  ngOnDestroy() {
    if (this.appStateSubscription) {
      this.appStateSubscription.unsubscribe();
    }
  }
  ngOnChanges(){
    if(this.x && this.x.instance){
      this.x.instance.dataInput= this.data;
    }
  }

}

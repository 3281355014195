import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { LoaderService } from '../../../services/loader.service';
import { LoaderStateModel } from '../../../services/loader.service';
import { TemplateConstants } from '../../commonComponent/template-constants';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  show: boolean;
  loaderPath: string = TemplateConstants.templateAssetsPath + '/images/loader.gif';
  private subscription: Subscription;

  constructor(
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe((state: LoaderStateModel) => {
      this.show = state.show;
      this.cdr.detectChanges()

    });
  }

  /**
   * unsubscribe all subscriptions when component destroys.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

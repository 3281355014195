import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { LsMdDialog, LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LoginService } from '../../../services/login.service';
import { UserModel } from '../../../common/models/user-model';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { CustomerSurveyService } from '../../../services/customerSurvey.service'
import { CustomerSurveyComponent } from '../../commonComponent/customer-survey/customer-survey.component'
import { AnswerModel } from '../../../common/models/answerModel'
import { CartCountService } from '../../../services/cart-count.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { CartModel } from '../../../common/models/cartModel';
import { CartProductModel } from '../../../common/models/cartProductModel';
import { CartService } from '../../../services/cart.service';
import { YesNoPopupComponent } from '../../commonComponent/yes-no-popup/yes-no-popup.component';

/**
 * This component displays the login-form. It also contains links for social sign-in.
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  dataList;
  dialog: LsMdDialog<LoginComponent>;
  loginForm: FormGroup
  registerForm: FormGroup
  loginMobileForm: FormGroup
  mobileRegisterForm: FormGroup
  user: UserModel
  fbOauth: boolean
  googleOauth: boolean
  email: string = ""
  password: string = ""
  rememberMe: boolean = false;
  formId: number
  loggedInUser: boolean = false;
  storeName = Constants.WAR_NAME;
  _cartCountSubscription;
  _surveyFormSubscription;

  constructor(
    public loginService: LoginService,
    public formBuilder: FormBuilder,
    private socialAuthService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    public customerSurveyService: CustomerSurveyService,
    public customerSurveyDialogService: LsDialogService<CustomerSurveyComponent>,
    private cartCountService: CartCountService,
    public okayMessageService: LsDialogService<OkayMessageComponent>,
    private cartService: CartService,
    private YesNoDialogService: LsDialogService<YesNoPopupComponent>
  ) { }

  /**
   * fetches socialMediaSettings of store, from which it is decided which social sign-in platforms will be visible.
   * fetches user's credentials from local-storage if present.
   * prepares form-groups for login and sign-up forms.
   */
  ngOnInit() {
    if (Constants.VENDOR_DETAILS != undefined && Constants.VENDOR_DETAILS.onlineStoreSettingDTO != undefined && Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO != undefined) {
      if (Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO.fbAppId && Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO.fbAppId != "") {
        this.fbOauth = true;
      }
      if (Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO.googleJSONFile && Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO.googleJSONFile != "") {
        this.googleOauth = true;
      }
    }

    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'email') != undefined && localStorage.getItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'email') != "") {
        let emailCookie = localStorage.getItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'email');
        let pwdCookie = localStorage.getItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'pwd');
        this.email = emailCookie;
        this.password = pwdCookie;
        this.rememberMe = true;
      }
      if (localStorage.getItem('currentUser') != null) {
        if (this.activatedRoute.snapshot.queryParamMap.get('moveTo') != undefined && this.activatedRoute.snapshot.queryParamMap.get('moveTo') == 'survey') {
          this.loggedInUser = true;
          this.navigateToHome()
        }
      }
    }

    this.loginForm = this.formBuilder.group({
      email: [this.email, [Validators.email, Validators.required]],
      password: [this.password, [Validators.required]],
      remember: [this.rememberMe]
    })

    this.registerForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      emailSignUp: ["", [Validators.email, Validators.required]],
      passwordSignUp: ["", [Validators.required]]
    })

    this.loginMobileForm = this.formBuilder.group({
      email: [this.email, [Validators.email, Validators.required]],
      password: [this.password, [Validators.required]],
      remember: [this.rememberMe]
    })

    this.mobileRegisterForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      emailSignUp: ["", [Validators.email, Validators.required]],
      passwordSignUp: ["", [Validators.required]]
    })
  }

  /**
   * unsubscribe all subscriptions when component destroys.
   */
  ngOnDestroy() {
    if (this._cartCountSubscription) {
      this._cartCountSubscription.unsubscribe();
    }
    if (this._surveyFormSubscription) {
      this._surveyFormSubscription.unsubscribe();
    }
  }

  /**
   * changes login-tab and signup tab
   * @param $event 
   * @param loginTab 
   * @param signUpForm 
   * @param login 
   * @param signUp 
   * @param errorLabel 
   */
  changeTab($event, loginTab, signUpForm, login, signUp, errorLabel) {
    if ($event.target.className === "signup-tab") {
      signUpForm.className = "signup-form";
      loginTab.className = "login-form hide";
      signUp.className = "signup-tab active";
      login.className = "login-tab";
      errorLabel.innerHTML = "";
    } else {
      signUpForm.className = "signup-form hide";
      loginTab.className = "login-form";
      signUp.className = "signup-tab";
      login.className = "login-tab active";
      errorLabel.innerHTML = "";
    }
  }

  data;//data send back by dialog to parent component(appcomponent)
  onClick() {
    this.dialog.close(null);//fns to close dialog
  }

  /**
   * This method gets triggered when user clicks on login-button.
   * It validates login-form and if form is valid, then it submits request for login.
   * After successful login, If user's shopping-cart is present and local-shopping-cart is also present, then cart-append handling is done.
   * Also if user's wish-list is present, then it is saved in local-wish-list cart.
   * and finally method proceeds with login after actions.
   * Also if user has checked remember-me checkbox, then user credentials are saved in local-storage so that next time user's login form is pre-filled with login creadentials.
   * @param $event- no use
   * @param errorLabel - element reference for displaying error.
   */
  loginSubmit($event, errorLabel) {
    let email, password;
    let rememberMe = this.loginForm.value.remember;
    if (this.loginForm.value.email != "") {
      email = this.loginForm.value.email;
      password = this.loginForm.value.password;
    } else {
      email = this.loginMobileForm.value.email;
      password = this.loginMobileForm.value.password;
    }
    if (this.loginForm.status === "INVALID" && this.loginMobileForm.status === "INVALID") {
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Field(s) required";
    } else {
      let userType = Constants.UserType.registered;
      this.loginService.login(this.rememberMe, email, password, false, userType, (resp) => {
        if (!resp.error && resp.data) {
          if (resp.otherInfo && resp.otherInfo.cartWishlist && resp.otherInfo.cartWishlist.data && resp.otherInfo.cartWishlist.data.length > 0) {
            let data: Array<CartModel> = resp.otherInfo.cartWishlist.data;
            let wishlistCart: CartModel, userCart: CartModel;
            if (data[0] && data[0].cartWishListType == Constants.CART_WISHLIST_TYPE.CART) {
              userCart = data[0];
            } else if (data[0] && data[0].cartWishListType == Constants.CART_WISHLIST_TYPE.WISHLIST) {
              wishlistCart = data[0];
            }
            if (data[1] && data[1].cartWishListType == Constants.CART_WISHLIST_TYPE.CART) {
              userCart = data[1];
            } else if (data[1] && data[1].cartWishListType == Constants.CART_WISHLIST_TYPE.WISHLIST) {
              wishlistCart = data[1];
            }
            if (wishlistCart) {
              this.setLocalCart(wishlistCart, true);
            }
            if (userCart) {
              this.cartAppendHandler(userCart);
            } else {
              this.afterLogin();
            }
          } else {
            let localCart = new CartModel();
            localCart = this.cartCountService.getLocalCartForStore();
            if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
              localCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
              this.computeCart(localCart);
            } else {
              this.afterLogin();
            }
          }
        } else {
          errorLabel.className = "errorLabel show";
          errorLabel.innerHTML = resp.msgList[0];
        }
        if (rememberMe && resp.data != undefined) {
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'email', email);
            localStorage.setItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'pwd', resp.data.data.password);
          }
        }
      })
    }
  }

  /**
   * This method gets triggered when user clicks on sign-up button after filling required fields.
   * It prepares StoreUseModel with name, email, password and validates sign-up form as well.
   * If form is valid, then sign-up request is submitted. 
   * After successful sign-up, if local-shopping-cart is present,then it is added to user's shopping-cart and then method proceeds for after login actions.
   */
  signUpSubmit($event, errorLabel) {
    let storeUserModel = new UserModel()
    let mode
    if (this.registerForm.value.emailSignUp != "") {
      storeUserModel.email = this.registerForm.value.emailSignUp
      storeUserModel.password = this.registerForm.value.passwordSignUp
      storeUserModel.userName = this.registerForm.value.name
    } else {
      storeUserModel.email = this.mobileRegisterForm.value.emailSignUp
      storeUserModel.password = this.mobileRegisterForm.value.passwordSignUp
      storeUserModel.userName = this.mobileRegisterForm.value.name
    }
    if (this.registerForm.status === "INVALID" && this.mobileRegisterForm.status === "INVALID") {
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Field(s) required";
    } else {
      errorLabel.className = "errorLabel hide";
      storeUserModel.userType = Constants.UserType.registered
      mode = Constants.LOGIN_MODE.DEFAULT
      this.loginService.sign_up(storeUserModel, null, mode, (resp) => {
        if (!resp.error && resp.data) {
          let localCart = new CartModel();
          localCart = this.cartCountService.getLocalCartForStore();
          if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
            localCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
            this.computeCart(localCart);
          }
          this.afterLogin();
        } else {
          errorLabel.className = "errorLabel show";
          errorLabel.innerHTML = resp.msgList[0];
        }
      });
    }
  }

  /**
   * performs social sign-in,
   * If successful sign-in, then 
   *        1. update shopping-cart as well as wishlist-cart in local-storage and in cart's observable as well,
   *        2. if cart before login 
   * @method socialSignIn
   * @param socialPlatform : string - contains social-platform from which login reuqest has been made
   * @param errorLabel - element that contains error-message
   */
  public socialSignIn(socialPlatform: string, errorLabel) {
    let socialPlatformProvider;
    if (socialPlatform === "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }
    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        let user: UserModel = new UserModel();
        user.userName = userData.name;
        user.email = userData.email;
        user.userType = Constants.UserType.registered;
        this.loginService.sign_up(user, null, Constants.LOGIN_MODE.OAUTH, resp => {
          if (!resp.error && resp.data) {
            if (resp.otherInfo && resp.otherInfo.cartWishlist && resp.otherInfo.cartWishlist.data && resp.otherInfo.cartWishlist.data.length > 0) {
              let data: Array<CartModel> = resp.otherInfo.cartWishlist.data;
              let wishlistCart: CartModel, userCart: CartModel;
              if (data[0] && data[0].cartWishListType == Constants.CART_WISHLIST_TYPE.CART) {
                userCart = data[0];
              } else if (data[0] && data[0].cartWishListType == Constants.CART_WISHLIST_TYPE.WISHLIST) {
                wishlistCart = data[0];
              }
              if (data[1] && data[1].cartWishListType == Constants.CART_WISHLIST_TYPE.CART) {
                userCart = data[1];
              } else if (data[1] && data[1].cartWishListType == Constants.CART_WISHLIST_TYPE.WISHLIST) {
                wishlistCart = data[1];
              }
              if (wishlistCart) {
                this.setLocalCart(wishlistCart, true);
              }
              if (userCart) {
                this.cartAppendHandler(userCart);
              } else {
                this.afterLogin();
              }
            } else {
              let localCart = new CartModel();
              localCart = this.cartCountService.getLocalCartForStore();
              if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
                localCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
                this.computeCart(localCart);
              } else {
                this.afterLogin();
              }
            }
          } else {
            errorLabel.className = "errorLabel show";
            errorLabel.innerHTML = resp.msgList[0];
          }
        });
      }
    )
  }

  /**
   * navigates to forgot-password page.
   * If, current component is diaog then closes current dialog.
   * @method goToForgotPw
   */
  goToForgotPw() {
    this.router.navigate(['forgot-password']);
    if (location.href.includes('login')) {
    } else {
      this.dialog.close(null);
    }
  }

  /**
   * Cart append handling - (append to previous cart ?)
   * LocalCart - Cart maintained before Login
   * LoginCart - Cart maintained after Login
   * If LocalCart and LoginCart both are not empty - then only pop-up will come. If yes - then both carts will merge o/w LocalCart will remain.
   * Otherwise, if only LocalCart exists - then LocalCart will remain.
   *            else (only LoginCart exists) - then LoginCart will remain. 
   * @method cartAppendHandler
   * @param prevUserCart : CartModel - contains cart previous to login
   */
  cartAppendHandler(prevUserCart: CartModel) {
    let localCart = new CartModel();
    localCart = this.cartCountService.getLocalCartForStore();
    if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0 && prevUserCart.cartProductModels && prevUserCart.cartProductModels.length > 0) {
      this.YesNoDialogService.open(YesNoPopupComponent, {}, "Do you want to append to previous cart?").subscribe(response => {
        if (response == "yes") {
          let updatedLocalCart: CartModel = new CartModel();
          updatedLocalCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
          updatedLocalCart.cartProductModels = localCart.cartProductModels;
          prevUserCart.cartProductModels.forEach((ele) => {
            let isPresent: boolean;
            for (var index = 0; index < localCart.cartProductModels.length; index++) {
              var localPro = localCart.cartProductModels[index];
              if (localPro.titleId == ele.titleId) {
                isPresent = true;
                if (localPro.variantId && ele.variantId) {
                  isPresent = false;
                  if (localPro.variantId == ele.variantId) {
                    isPresent = true;
                    break;
                  }
                }
              }
            }
            if (!isPresent) {
              updatedLocalCart.cartProductModels.push(ele);
            }
          });
          this.computeCart(updatedLocalCart);
        } else if (response == "no") {
          localCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
          this.computeCart(localCart);
        }
      });
    } else if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
      localCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
      this.computeCart(localCart);
    } else if (prevUserCart.cartProductModels && prevUserCart.cartProductModels.length > 0) {
      this.setLocalCart(prevUserCart, false);
    }
    this.afterLogin();
  }

  /**
   * sends reuqest for computing updated cart,
   * update new cart in local-storage.
   * @method computeCart
   * @param cart : CartModel
   */
  computeCart(cart: CartModel) {
    this.cartService.computeCart(cart, (resp) => {
      if (!resp.error && resp.data && resp.data[0]) {
        this.setLocalCart(resp.data[0], false);
      } else {
        this.okayMessageService.open(OkayMessageComponent, {}, 'Error appending to previous cart!');
      }
    });
  }

  /**
   * update shopping-cart or wish-list in local-storage according to the CartModel.
   * @param cart : CartModel which needs to be updated.
   * @param isWishlist : flag, if cart is shopping-cart or wishlist.
   */
  setLocalCart(cart: CartModel, isWishlist: boolean) {
    let currCart = this.cartCountService.setLocalCart(cart, isWishlist);
    if (cart) {
      let cart = currCart;
      if (!isWishlist) {
        this.cartCountService.updateCart(cart);
      } else {
        this.cartCountService.updateWishlistCart(cart);
      }
    }
  }

  /**
   * performs actions required after successful login, based upon query-params of current route.
   * If, queryParams' moveTo is equals to profile, then navigate to user's dashboard,
   * else navigate to home-page.
   * If queryParams doesn't exist, and current-url doesn't includes 'login', then it must be a dialog otherwise it would have been a route.
   * If, it's dialog, then close the dialog after successful login.
   * @method afterLogin
   */
  afterLogin() {
    if (this.activatedRoute.snapshot.queryParamMap.get('moveTo') != undefined) {
      if (this.activatedRoute.snapshot.queryParamMap.get('moveTo') == 'profile') {
        let navigateUrl = 'my-dashboard/' + this.activatedRoute.snapshot.queryParamMap.get('moveTo').toString();
        this.router.navigate([navigateUrl]);
      } else if (this.activatedRoute.snapshot.queryParamMap.get('moveTo') == 'survey') {
        this.navigateToHome();
      }
    } else if (location.href.includes('login')) {
      if (this.activatedRoute.snapshot.queryParamMap.get('moveTo') != undefined &&
        this.activatedRoute.snapshot.queryParamMap.get('moveTo') == 'survey') {
        this.navigateToHome();
      } else {
        location.href = "my-dashboard/profile";
      }
    } else {
      this.dialog.close(null);
    }
  }

  /**
   * redirects to home
   * For multi-store, redirect user to 'store/<current store domain>',
   * Else, redirect user to ' ' (empty) route
   */
  navigateToHome() {
    if (Constants.IS_MULTI_STORE && this.cartCountService.getSelectedStoreDomain()) {
      let storeHomeUrl = '/store/' + this.cartCountService.getSelectedStoreDomain();
      this.router.navigate([storeHomeUrl]);
    } else {
      this.router.navigate(['']);
    }
  }

  openSurvey() {
    let data: Array<{ formId: number }> = []
    this.customerSurveyService.getSurveyForm(resp => {
      if (!resp.error) {
        if (resp.data != undefined && resp.data != null && resp.data.formId != undefined && resp.data.formId != null)
          this.formId = resp.data.formId
        data.push({
          formId: this.formId
        })
        this.customerSurveyDialogService.open(CustomerSurveyComponent, {}, data).subscribe((response) => {
          if (response == 'yes') {
            let answerModel = new AnswerModel()
            answerModel.formId = this.formId
            let isSurveyFormFilled = false
            this._surveyFormSubscription = this.cartCountService.surveyFormFillObservable.subscribe((flag) => {
              isSurveyFormFilled = flag
            })
            if (!isSurveyFormFilled) {
              this.customerSurveyService.submitQuestionsForSurvey(answerModel, (resp) => {
                if (!resp.error)
                  this.navigateToHome()
              })
            } else {
              this.navigateToHome()
            }
          }
        })
      } else {
        this.navigateToHome()
        this.okayMessageService.open(OkayMessageComponent, null, 'Survey has been already completed!').subscribe(response => {
        })
      }
    })
  }

  /**
   * method shows password strength based upon password's strength calculation
   * @method manageStrength
   * @param pwdStrength : element which display password's strength
   */
  manageStrength(pwdStrength) {
    let password = this.registerForm.value.passwordSignUp
    if (password.length == 0) {
      pwdStrength.className = "pwdStrength";
    } else if (password.length < 6 && password.length > 0) {
      pwdStrength.className = "pwdStrength short";
      pwdStrength.innerHTML = "Too short";
    } else {
      let strength = this.getStrength(password, 0);
      /**
       * if, password's strength is greater than 2, then password is strong,
       * else if, equals to 2, then password is good,
       * else password is weak.
       */
      if (strength < 2) {
        pwdStrength.className = "pwdStrength weak";
        pwdStrength.innerHTML = "Weak";
      } else if (strength == 2) {
        pwdStrength.className = "pwdStrength good";
        pwdStrength.innerHTML = "Good";
      } else {
        pwdStrength.className = "pwdStrength strong";
        pwdStrength.innerHTML = "Strong";
      }
    }
  }

  /**
   * return strength of password.
   * @method getStrength
   * @param password : password of which strength needs to be calculated
   * @param strength : holds strength of password
   * @returns strength : strength of password
   */
  getStrength(password, strength) {
    //if length is 8 characters or more, increase strength value
    if (password.length > 7)
      strength += 1;
    //if password contains both lower and uppercase characters, increase strength value
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/))
      strength += 1;
    //if it has numbers and characters, increase strength value
    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/))
      strength += 1;
    //if it has one special character, increase strength value
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/))
      strength += 1;
    //if it has two special characters, increase strength value
    if (password.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,%,&,@,#,$,^,*,?,_,~])/))
      strength += 1;
    return strength;
  }

}

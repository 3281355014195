import { Component, OnInit, Inject, PLATFORM_ID, ViewChild, OnDestroy, Input } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { HomePageService } from '../../../../../services/home-page.service';
import { CollectionModel } from '../../../../../common/models/collectionModel';
import { isPlatformBrowser } from '@angular/common';
import { Constants } from '../../../../../common/constants/lsnetx.constants';
import { CartService } from '../../../../../services/cart.service';
import { CartModel } from '../../../../../common/models/cartModel';
import { CartProductModel } from '../../../../../common/models/cartProductModel';
import { OkayMessageComponent } from '../../../../commonComponent/okay-message/okay-message.component';
import { LsDialogService } from '../../../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LoginComponent } from '../../../../components/login/login.component';
import { CartCountService } from '../../../../../services/cart-count.service';
import { CategoryListPageService } from '../../../../../services/categoryListPage.service';
import { ProductFilterModel } from '../../../../../common/models/productFilterModel';

/**
 * This component displays home-page collections or detail page collection.
 */
@Component({
  selector: 'app-home-products',
  templateUrl: './home-products.component.html',
  styleUrls: ['./home-products.component.scss']
})
export class HomeProductsComponent implements OnInit, OnDestroy {

  left: number = 0
  rightVisibility: boolean = true
  leftVisibility: boolean = false
  slideConst: number = 0;
  collectionArray: Array<CollectionModel> = [];
  leftValue: Array<number> = [];
  maxLeftValue: Array<number> = [];
  showCount: number = 4;
  @ViewChild('container', {static: false}) container: any;
  isDetailPage: boolean = false;
  _routeSubscription;
  _routeSubscription1;
  _homePageServiceSubscription;
  constants = Constants;
  @Input('newProd') newProd;
  collPageNum: Array<number> = [];
  hasMore: Array<boolean> = [];

  constructor(
    private homePageService: HomePageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
    public dialogService: LsDialogService<LoginComponent>,
    public cartCountService: CartCountService,
    public cartService: CartService,
    private categoryListPageService: CategoryListPageService
  ) { }

  /**
   * Fetch collections to be populated.
   */
  ngOnInit() {
    if (this.router.url.includes('details/')) {
      this.isDetailPage = true;
    } else {
      this.isDetailPage = false;
    }
    this._routeSubscription = this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        let newUrl = e.url;
        if (newUrl.includes('details/')) {
          this.isDetailPage = true;
        } else {
          this.isDetailPage = false;
        }
      }
    });
    if(!this.isDetailPage){
      this.getCollections();
    }
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 768) {
        this.showCount = 2;
      }
    }
  }

  /**
   * If current route is 'detail' page, then fetch collection for detail-page, else fetch collection for 'home-page'. 
   */
  getCollections() {
    var fetchProducts = true, categoryId;
    this.collectionArray = [];
    if (this.router.url.includes('details/')) {
      this.isDetailPage = true;
    } else {
      this.isDetailPage = false;
    }
    this._routeSubscription1 = this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        let newUrl = e.url;
        if (newUrl.includes('details/')) {
          this.isDetailPage = true;
        } else {
          this.isDetailPage = false;
        }
      }
    });
    if(this.isDetailPage){
      if(this.newProd && this.newProd.titleId && this.newProd.categoryId){
        categoryId = this.newProd.categoryId
      }
    }
    this.homePageService.getHomePageCollectionIn(this.isDetailPage, fetchProducts, categoryId, (data) => {
      if (data != null && !data.error) {
        if (data.data != null || data.data != undefined) {
          let collection: Array<CollectionModel> = data.data;
          if (collection && collection.length > 0) {
            collection.forEach(element => {
              this.collectionArray.push(element);
            });
          }
          this.homePageService.collectionsBehaviour.next(this.collectionArray);
          for (let index = 0; index < this.collectionArray.length; index++) {
            this.leftValue.push(0);
            this.collPageNum.push(1);
            this.hasMore.push(false);
          }
        }
      }
    });
  }

  /**
   * @deprecated
   */
  getCollectionOnCategory(categoryUrl, index: number){
    let currencyId = 1, divisionId = 0, filterModel, numRecords = this.showCount;
    if (isPlatformBrowser(this.platformId)) {
      if (window.sessionStorage.getItem('currencyId') != null) {
        currencyId = JSON.parse(window.sessionStorage.getItem('currencyId'));
      }
    }
    let productFilterModel = new ProductFilterModel();
    productFilterModel.categoryUrl = categoryUrl;
    productFilterModel.pageNum = this.collPageNum[index];
    productFilterModel.numRecords = numRecords;
    productFilterModel.currencyId = currencyId;
    productFilterModel.divisionId = divisionId;
    productFilterModel.filterModels = filterModel;
    productFilterModel.productOnly = true;
    this.categoryListPageService.getDataOnCategory(productFilterModel, Constants.SEARCH_PRODUCTS_REQ_MODE.BOTH, (resp) => {
      if (resp != null) {
        if (!resp.error) {
        }
      }
    });
  }

  ngOnChanges() {
    if (this.newProd) {
    /**
     * set products scroll to initial
     */
      this.leftValue = [];
      this.getCollections();
    }
  }

  /**
   * unsubscribe all subscriptions when component destroys.
   */
  ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    if (this._routeSubscription1) {
      this._routeSubscription1.unsubscribe();
    }
  }

  initializeSliderSettings(container, idx) {
    if (this.slideConst == 0)
      this.slideConst = container.nativeElement.offsetWidth;
    this.maxLeftValue[idx] = -this.slideConst * (this.collectionArray[idx].productModels.length - this.showCount);
  }

  moveLeft(container, idx) {
    ++this.collPageNum[idx];
    this.initializeSliderSettings(container, idx);
    if (this.leftValue[idx] > this.maxLeftValue[idx]) {
      this.leftValue[idx] -= this.slideConst * this.showCount + 10 * this.showCount;
    }
  }

  moveRight(container, idx) {
    this.initializeSliderSettings(container, idx);
    if (this.leftValue[idx] < 0) {
      this.leftValue[idx] += this.slideConst * this.showCount + 10 * this.showCount;
    }
  }

  /**
   * Adds product to shopping-cart.
   * For fetching local-shopping-cart, if it is the multi-stores case, it fetches local-shopping-cart for selected store.
   * If not Login, 
   * 1. check if localCart is empty, 
   *    a)if yes, then create and update new cart and save new cart in localStorage and update observable.
   *    b)if no, then update localCart and save updated cart in localStorage and update observable.
   * If Login (
   *    1. get prevUserCart and check if localCart is empty, then create and update new cart with prevUserCart
   *      and save it in localStorage and update cartObsevable.
   *      if local is not empty, then update prevUserCart with localCart and save it in localStorage and update
   *      cartObservable.)
   * @param event : titleId of the product which needs to be added in the shopping-cart.
   */
  addToCartProduct(event) {
    if (isPlatformBrowser(this.platformId)) {
      let localCart: CartModel = this.cartCountService.getLocalCartForStore();
      let newProd: CartProductModel = new CartProductModel();
      newProd.titleId = Number.parseInt(event);
      newProd.numCopies = 1;
      if (window.localStorage.getItem('currentUser') !== null) {
        let newCart: CartModel = new CartModel();
        newCart.cartProductModels = [];
        newCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
        if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
          localCart.cartProductModels.push(newProd);
          newCart.cartProductModels = localCart.cartProductModels;
        } else {
          newCart.cartProductModels.push(newProd);
        }
        if (!this.checkProductAlreadyPresent(event, false)) {
          this.cartService.computeCart(newCart, (resp) => {
            if (!resp.error && resp.data) {
              this.setLocalCart(resp.data[0], false);
              this.cartCountService.updateCart(resp.data[0]);
              this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Product Added to Cart!');
            } else {
              this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Error in adding product to cart!');
            }
          });
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
        }
      } else {
        let newCart: CartModel = new CartModel();
        newCart.cartProductModels = [];
        newCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
        if (localCart) {
          localCart.cartProductModels.push(newProd);
          newCart.cartProductModels = localCart.cartProductModels;
        } else {
          newCart.cartProductModels.push(newProd);
        }
        if (!this.checkProductAlreadyPresent(event, false)) {
          this.setLocalCart(newCart, false);
          this.cartCountService.updateCart(newCart);
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Product Added to Cart!');
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
        }
      }
    }
  }
  
  /**
   * Adds product to wishlist if user is logged-in, else first open login pop-up and after sucessful login, add product to wishlist.
   * @param event : product that needs to be added to the wishlist.
   */
  addToWishlist(event: CartProductModel) {
    if (window.localStorage.getItem('currentUser') !== null) {
      this.addToCartWishlistUtility(event, false);
    } else {
      this.dialogService.open(LoginComponent, { panelClass: 'loginPopUp' }, null).subscribe(response => {
        if (window.localStorage.getItem('currentUser') !== null) {
          this.addToCartWishlistUtility(event, false);
        }
      });
    }
  }

  /**
   * Adds a product to the wishlist.
   * First, get the current wish-list from local-storage,
   * then, check that if product is already present in the wish-list or not.
   * if not already present, push product in the beginning of the exisiting product-list, and call method for compute-cart and update local-wishlist.
   * else show error-msg.
   * @param event : product-model which needs to be added to the wish-list.
   * @param isLoginAction : deprecated (only used when cart is shown on a page on a route, not as a dialog)
   */
  addToCartWishlistUtility(event: CartProductModel, isLoginAction: boolean) {
    let msg = 'Added to your Wishlist!';
    let errMsg = 'Error in adding product to wishlist!';
    let localWishlistCart: CartModel;
    if (localStorage.getItem('wishlist')) {
      localWishlistCart = JSON.parse(localStorage.getItem('wishlist'));
    }
    let newWishlistCart: CartModel = new CartModel();
    newWishlistCart.cartProductModels = [];
    newWishlistCart.cartProductModels.push(event);
    newWishlistCart.cartWishListType = Constants.CART_WISHLIST_TYPE.WISHLIST;
    if (localWishlistCart && localWishlistCart.cartProductModels && localWishlistCart.cartProductModels.length > 0) {
      localWishlistCart.cartProductModels.forEach((ele: CartProductModel) => {
        newWishlistCart.cartProductModels.push(ele);
      });
    }
    if (!this.checkProductAlreadyPresent(event.titleId, true)) {
      this.cartService.computeCart(newWishlistCart, (resp) => {
        if (!resp.error && resp.data && resp.data[0]) {
          this.setLocalCart(resp.data[0], true);
          this.okayMessageDialogService.open(OkayMessageComponent, {}, msg);
        }
      });
    } else {
      this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
    }
  }
  
  /**
   * update shopping-cart or wish-list in local-storage according to the CartModel.
   * @param cart : CartModel which needs to be updated.
   * @param isWishlist : flag, if cart is shopping-cart or wishlist.
   */
  setLocalCart(cart: CartModel, isWishlist: boolean) {
    this.cartCountService.setLocalCart(cart, isWishlist);
  }

  /**
   * checks a product is already present in shopping-cart or in wish-list.
   * In multi-store case, it fetches local-shopping-cart for the selected store.
   * @param id : titleID of product.
   * @param isWishlist : flag where to check for product, in shopping-cart or in wish-list.
   * @returns boolean : true, if product is already present in shopping-cart or in wishlist, else returns false.  
   */
  checkProductAlreadyPresent(id: number, isWishlist: boolean): boolean {
    let localCart;
    if (isWishlist) {
      localCart = JSON.parse(localStorage.getItem('wishlist'));
    } else {
      localCart = this.cartCountService.getLocalCartForStore();
    }
    let present: boolean;
    if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
      for (let index = 0; index < localCart.cartProductModels.length; index++) {
        var element = localCart.cartProductModels[index];
        if (element.titleId == id) {
          present = true;
          break;
        }
      }
    }
    if (present) {
      return true;
    } else {
      return false;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OnlineStoreModel } from '../../../common/models/online-store-model';
import { Constants } from '../../../common/constants/lsnetx.constants';

/**
 * This component is displayed as okay-pop-up which is only for displaying any message to user. 
 */
@Component({
  selector: 'app-okay-message',
  templateUrl: './okay-message.component.html',
  styleUrls: ['./okay-message.component.scss']
})
export class OkayMessageComponent implements OnInit {

  /**
   * message to be displayed in pop-up
   */
  msg;
  /**
   * dialog instance
   */
  dialog: LsMdDialog<OkayMessageComponent>
  /**
   * data provided to this comoponent when opended as a pop-up.
   */
  dataList;
  /**
   * flag for displaying checkout-agreement static-page's content
   */
  showCheckoutAgreement: boolean;
  /**
   * flag for displaying okay-popup content
   */
  showOkPopup: boolean = true;
  /**
   * contains checkout-agreement static-page's content
   */
  checkoutAgreement: string;
  /**
   * contains company-details
   */
  companyDetails: OnlineStoreModel;

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Fetches message from dataList and displays it.
   * If message contains 'checkout-agreement', then displays checkout-agreement static page's content.
   * Fetch checkout-agreement page's url from global company-details.
   */
  ngOnInit() {
    this.msg = this.dataList;
    if(this.msg == 'checkout-agreement'){
      this.companyDetails = Constants.VENDOR_DETAILS;
      var cdnPath = this.companyDetails.checkoutAgreementUrl;
      if (cdnPath != undefined && cdnPath != "") {
        this.http.get(cdnPath).subscribe((data: any) => {
          this.checkoutAgreement = data._body;
        });
      }
     this.showCheckoutAgreement = true;
     this.showOkPopup = false;
    }
  }

  /**
   * Closes this dialog.
   */
  closeDialog() {
    this.dialog.close(null);
  }

}
